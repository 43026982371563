/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #2f2575;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #ec7b51;
  --yellow: #f0b400;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2f2575;
  --secondary: #ec7b51;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #f0b400;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1600px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: IntroCond;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2f2575;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2f2575;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #18133b;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.125rem; }

h2, .h2 {
  font-size: 1.7rem; }

h3, .h3 {
  font-size: 1.4875rem; }

h4, .h4 {
  font-size: 1.275rem; }

h5, .h5 {
  font-size: 1.0625rem; }

h6, .h6 {
  font-size: 0.85rem; }

.lead {
  font-size: 1.0625rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.0625rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid, .cms_content .image img {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto; }

.figure, .cms_content .image {
  display: inline-block; }

.figure-img, .cms_content .image img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption, .cms_content .image figcaption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #2f2575; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered, .cms_content .table {
  border: 1px solid #dee2e6; }
  .table-bordered th, .cms_content .table th,
  .table-bordered td,
  .cms_content .table td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th, .cms_content .table thead th,
  .table-bordered thead td,
  .cms_content .table thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd), .cms_content .table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #2f2575;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c5c2d8; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #938eb7; }

.table-hover .table-primary:hover {
  background-color: #b6b2ce; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b6b2ce; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fadace; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f5baa5; }

.table-hover .table-secondary:hover {
  background-color: #f8c9b7; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f8c9b7; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbeab8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f7d87a; }

.table-hover .table-warning:hover {
  background-color: #fae3a0; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fae3a0; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered, .cms_content .table-dark.table {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd), .cms_content .table-dark.table tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered, .cms_content .table-responsive-sm > .table {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered, .cms_content .table-responsive-md > .table {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered, .cms_content .table-responsive-lg > .table {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered, .cms_content .table-responsive-xl > .table {
      border: 0; } }

@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered, .cms_content .table-responsive-xxl > .table {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered, .cms_content .table-responsive > .table {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #6051c8;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(47, 37, 117, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.0625rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.74375rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.85rem;
  line-height: 1.5;
  color: #2f2575;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.74375rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.1rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.74375rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.74375rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #2f2575;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.85rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #2f2575;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(47, 37, 117, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #2f2575;
  border-color: #2f2575; }
  .btn-primary:hover {
    color: #fff;
    background-color: #231c58;
    border-color: #1f194e; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #231c58;
    border-color: #1f194e;
    box-shadow: 0 0 0 0.2rem rgba(78, 70, 138, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #2f2575;
    border-color: #2f2575; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1f194e;
    border-color: #1c1645; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(78, 70, 138, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #ec7b51;
  border-color: #ec7b51; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #e8612f;
    border-color: #e75823; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #e8612f;
    border-color: #e75823;
    box-shadow: 0 0 0 0.2rem rgba(206, 110, 75, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #ec7b51;
    border-color: #ec7b51; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #e75823;
    border-color: #e45019; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(206, 110, 75, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #f0b400;
  border-color: #f0b400; }
  .btn-warning:hover {
    color: #fff;
    background-color: #ca9700;
    border-color: #bd8e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #ca9700;
    border-color: #bd8e00;
    box-shadow: 0 0 0 0.2rem rgba(209, 159, 6, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #f0b400;
    border-color: #f0b400; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #bd8e00;
    border-color: #b08400; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(209, 159, 6, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #2f2575;
  border-color: #2f2575; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2f2575;
    border-color: #2f2575; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 37, 117, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #2f2575;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2f2575;
    border-color: #2f2575; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(47, 37, 117, 0.5); }

.btn-outline-secondary {
  color: #ec7b51;
  border-color: #ec7b51; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #ec7b51;
    border-color: #ec7b51; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 123, 81, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #ec7b51;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #ec7b51;
    border-color: #ec7b51; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(236, 123, 81, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #f0b400;
  border-color: #f0b400; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #f0b400;
    border-color: #f0b400; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 180, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f0b400;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #f0b400;
    border-color: #f0b400; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(240, 180, 0, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #2f2575;
  text-decoration: none; }
  .btn-link:hover {
    color: #18133b;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.3rem;
  font-size: 0.66938rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.85rem;
  color: #2f2575;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1600px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #2f2575; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.74375rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.225rem;
  padding-left: 0.225rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.74375rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.275rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.1375rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2f2575;
    background-color: #2f2575; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(47, 37, 117, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #6051c8; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #8478d4;
    border-color: #8478d4; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.1375rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.1375rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2f2575;
  background-color: #2f2575; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(47, 37, 117, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(47, 37, 117, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(47, 37, 117, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.1375rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(47, 37, 117, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #6051c8;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(47, 37, 117, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.74375rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.0625rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #6051c8;
    box-shadow: 0 0 0 0.2rem rgba(47, 37, 117, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0 0 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(47, 37, 117, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(47, 37, 117, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(47, 37, 117, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2f2575;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #8478d4; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2f2575;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #8478d4; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #2f2575;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #8478d4; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2f2575; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.34062rem;
  padding-bottom: 0.34062rem;
  margin-right: 1rem;
  font-size: 1.0625rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.0625rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1599.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 0 0 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 0 0; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #2f2575;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #18133b;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(47, 37, 117, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2f2575;
  border-color: #2f2575; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.0625rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.74375rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #2f2575; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #1f194e; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(47, 37, 117, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #ec7b51; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #e75823; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(236, 123, 81, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #f0b400; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #bd8e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(240, 180, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.775rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #18133d;
  background-color: #d5d3e3;
  border-color: #c5c2d8; }
  .alert-primary hr {
    border-top-color: #b6b2ce; }
  .alert-primary .alert-link {
    color: #090716; }

.alert-secondary {
  color: #7b402a;
  background-color: #fbe5dc;
  border-color: #fadace; }
  .alert-secondary hr {
    border-top-color: #f8c9b7; }
  .alert-secondary .alert-link {
    color: #552c1d; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #7d5e00;
  background-color: #fcf0cc;
  border-color: #fbeab8; }
  .alert-warning hr {
    border-top-color: #fae3a0; }
  .alert-warning .alert-link {
    color: #4a3800; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.6375rem;
  background-color: #e9ecef;
  border-radius: 0; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2f2575;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #2f2575;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2f2575;
    border-color: #2f2575; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #18133d;
  background-color: #c5c2d8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #18133d;
    background-color: #b6b2ce; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #18133d;
    border-color: #18133d; }

.list-group-item-secondary {
  color: #7b402a;
  background-color: #fadace; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #7b402a;
    background-color: #f8c9b7; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #7b402a;
    border-color: #7b402a; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #7d5e00;
  background-color: #fbeab8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7d5e00;
    background-color: #fae3a0; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7d5e00;
    border-color: #7d5e00; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.275rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: IntroCond;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.74375rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: IntroCond;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.74375rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.85rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #2f2575; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary, .account_table .hover-primary:hover {
  background-color: #2f2575 !important; }

a.bg-primary:hover, .account_table a.hover-primary:hover, a.bg-primary:focus, .account_table a.hover-primary:focus:hover,
button.bg-primary:hover,
.account_table button.hover-primary:hover,
button.bg-primary:focus,
.account_table button.hover-primary:focus:hover {
  background-color: #1f194e !important; }

.bg-secondary {
  background-color: #ec7b51 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e75823 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #f0b400 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #bd8e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #2f2575 !important; }

.border-secondary {
  border-color: #ec7b51 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #f0b400 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1600px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3, .cms_content .image.image-style-align-left,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3, .cms_content .image.image-style-align-right,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center, .cms_content .image figcaption {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white, .account_table .hover-primary:hover {
  color: #fff !important; }

.text-primary {
  color: #2f2575 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #18133b !important; }

.text-secondary {
  color: #ec7b51 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #d94c18 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #f0b400 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #a47b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #2f2575 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th, .cms_content .table th,
  .table-bordered td,
  .cms_content .table td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* Webfont: MysteryTourSans */
@font-face {
  font-family: "MysteryTourSans";
  src: url("/fonts/MysteryTourSans.eot");
  /* IE9 Compat Modes */
  src: url("/fonts/MysteryTourSans.eot?#iefix") format("embedded-opentype"), url("/fonts/MysteryTourSans.woff") format("woff"), url("/fonts/MysteryTourSans.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap; }

@font-face {
  font-family: "IntroCond";
  src: url("/fonts/IntroCond/38B671_0_0.eot");
  src: url("/fonts/IntroCond/38B671_0_0.eot?#iefix") format("embedded-opentype"), url("/fonts/IntroCond/38B671_0_0.woff2") format("woff2"), url("/fonts/IntroCond/38B671_0_0.woff") format("woff"), url("/fonts/IntroCond/38B671_0_0.ttf") format("truetype");
  font-weight: 900;
  font-display: swap; }

@font-face {
  font-family: "IntroCond";
  src: url("/fonts/IntroCond/38B671_4_0.eot");
  src: url("/fonts/IntroCond/38B671_4_0.eot?#iefix") format("embedded-opentype"), url("/fonts/IntroCond/38B671_4_0.woff2") format("woff2"), url("/fonts/IntroCond/38B671_4_0.woff") format("woff"), url("/fonts/IntroCond/38B671_4_0.ttf") format("truetype");
  font-weight: 700 800;
  font-display: swap; }

@font-face {
  font-family: "IntroCond";
  src: url("/fonts/IntroCond/38B671_8_0.eot");
  src: url("/fonts/IntroCond/38B671_8_0.eot?#iefix") format("embedded-opentype"), url("/fonts/IntroCond/38B671_8_0.woff2") format("woff2"), url("/fonts/IntroCond/38B671_8_0.woff") format("woff"), url("/fonts/IntroCond/38B671_8_0.ttf") format("truetype");
  font-weight: 200 300;
  font-display: swap; }

@font-face {
  font-family: "IntroCond";
  src: url("/fonts/IntroCond/38B671_C_0.eot");
  src: url("/fonts/IntroCond/38B671_C_0.eot?#iefix") format("embedded-opentype"), url("/fonts/IntroCond/38B671_C_0.woff2") format("woff2"), url("/fonts/IntroCond/38B671_C_0.woff") format("woff"), url("/fonts/IntroCond/38B671_C_0.ttf") format("truetype");
  font-weight: 400;
  font-display: swap; }

@font-face {
  font-family: "IntroCond";
  src: url("/fonts/IntroCond/38B671_10_0.eot");
  src: url("/fonts/IntroCond/38B671_10_0.eot?#iefix") format("embedded-opentype"), url("/fonts/IntroCond/38B671_10_0.woff2") format("woff2"), url("/fonts/IntroCond/38B671_10_0.woff") format("woff"), url("/fonts/IntroCond/38B671_10_0.ttf") format("truetype");
  font-weight: 500 600;
  font-display: swap; }

@font-face {
  font-family: "IntroCond";
  src: url("/fonts/IntroCond/38B671_14_0.eot");
  src: url("/fonts/IntroCond/38B671_14_0.eot?#iefix") format("embedded-opentype"), url("/fonts/IntroCond/38B671_14_0.woff2") format("woff2"), url("/fonts/IntroCond/38B671_14_0.woff") format("woff"), url("/fonts/IntroCond/38B671_14_0.ttf") format("truetype");
  font-weight: 100;
  font-display: swap; }

#root {
  text-align: justify; }

@media (min-height: 0) {
  .dh-none {
    display: none !important; }
  .dh-inline {
    display: inline !important; }
  .dh-inline-block {
    display: inline-block !important; }
  .dh-block {
    display: block !important; }
  .dh-table {
    display: table !important; }
  .dh-table-row {
    display: table-row !important; }
  .dh-table-cell {
    display: table-cell !important; }
  .dh-flex {
    display: flex !important; }
  .dh-inline-flex {
    display: inline-flex !important; } }

@media (min-height: 920px) {
  .dh-lg-none {
    display: none !important; }
  .dh-lg-inline {
    display: inline !important; }
  .dh-lg-inline-block {
    display: inline-block !important; }
  .dh-lg-block {
    display: block !important; }
  .dh-lg-table {
    display: table !important; }
  .dh-lg-table-row {
    display: table-row !important; }
  .dh-lg-table-cell {
    display: table-cell !important; }
  .dh-lg-flex {
    display: flex !important; }
  .dh-lg-inline-flex {
    display: inline-flex !important; } }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: MysteryTourSans;
  text-transform: lowercase;
  letter-spacing: 1.2; }

@media (max-width: 767.98px) {
  h1, .h1 {
    font-size: 1.7rem; } }

.badge {
  font-size: 12px; }

button:focus,
.btn:focus {
  outline: none;
  box-shadow: none; }

.btn-secondary {
  color: #fff; }

.btn-warning {
  color: #fff; }

.share_btn {
  border-radius: 25px;
  width: 40px;
  height: 40px;
  font-size: 22px;
  border: 1px solid #2f2575;
  text-decoration: none; }

.btn-ressources {
  padding-top: 8px;
  border: 1px solid white;
  background-color: #171546;
  color: #ec7b51;
  border-radius: 1.25rem;
  text-transform: uppercase;
  font-size: 10px; }

.btn-ressources:hover {
  background-color: white;
  color: #2f2575;
  border: 1px solid #171546; }

.btn-ressources:not(:disabled):not(.disabled):active,
.btn-ressources:not(:disabled):not(.disabled).active,
.show > .btn-ressources.dropdown-toggle {
  background-color: white;
  color: #2f2575;
  border: 1px solid #171546; }

.link_decoration a {
  text-decoration: none; }

.pointer {
  cursor: pointer; }

.btn-blog {
  border: 1px solid white;
  background-color: #171546;
  color: white;
  border-radius: 50px;
  text-transform: lowercase;
  font-size: 10px;
  font-weight: 400;
  line-height: 2;
  font-family: MysteryTourSans;
  padding: 9px 12px 7px;
  letter-spacing: 1px; }

.btn-blog:hover {
  background-color: white;
  color: #2f2575;
  border: 1px solid #171546; }

.btn-blog:not(:disabled):not(.disabled):active,
.btn-blog:not(:disabled):not(.disabled).active,
.show > .btn-blog.dropdown-toggle {
  background-color: white;
  color: #2f2575;
  border: 1px solid #ec7b51; }

.disabled,
.btn.disabled,
.btn:disabled,
button:disabled,
a.btn.disabled {
  cursor: not-allowed;
  pointer-events: initial;
  opacity: 0.65; }

.loading_message,
.error_message {
  padding: 5% 0; }
  .loading_message .card-content,
  .error_message .card-content {
    padding: 3rem; }
    .loading_message .card-content i,
    .error_message .card-content i {
      font-size: 3rem; }

.dropdown-menu {
  max-height: 92vh;
  overflow: auto; }

.events .event p {
  color: #fff; }

.events .event .title {
  text-transform: uppercase;
  font-weight: bold;
  text-align: left; }

.events .event button {
  border-width: 2px;
  background-color: #fff; }
  .events .event button:hover {
    background: #2f2575;
    color: #fff; }

option.has-warning {
  background: #f0b400; }

.modal-header .close {
  padding: 1rem 1rem 0 1rem !important;
  font-size: 2rem; }

.modal-dialog.modal-full-width {
  max-width: none;
  width: 96%; }

.modal-dialog.modal-full-height .modal-content {
  height: 94vh; }
  .modal-dialog.modal-full-height .modal-content .modal-body {
    position: absolute;
    /* padding: 15px; */
    top: 57px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto; }
  .modal-dialog.modal-full-height .modal-content .modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%; }

.modal-dialog.modal-full-height.modal-dialog-footer .modal-body {
  bottom: 67px; }

.modal-dialog.modal-full-height.modal-dialog-header .modal-body {
  top: 66px; }

.form-group.has-warning,
.form-check.has-warning {
  color: #f0b400; }
  .form-group.has-warning .form-control,
  .form-check.has-warning .form-control {
    border-color: #f0b400; }
    .form-group.has-warning .form-control:focus,
    .form-check.has-warning .form-control:focus {
      border-color: #f0b400;
      box-shadow: 0 0 0 0.2rem rgba(240, 180, 0, 0.25); }

.form-control.has-warning {
  border-color: #f0b400; }
  .form-control.has-warning:focus {
    border-color: #f0b400;
    box-shadow: 0 0 0 0.2rem rgba(240, 180, 0, 0.25); }

.form-group.has-error,
.form-check.has-error {
  color: #dc3545; }
  .form-group.has-error .form-control,
  .form-check.has-error .form-control {
    border-color: #dc3545; }
    .form-group.has-error .form-control:focus,
    .form-check.has-error .form-control:focus {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-control.has-error {
  border-color: #dc3545; }
  .form-control.has-error:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-group.has-success,
.form-check.has-success {
  color: #28a745; }
  .form-group.has-success .form-control,
  .form-check.has-success .form-control {
    border-color: #28a745; }
    .form-group.has-success .form-control:focus,
    .form-check.has-success .form-control:focus {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.form-control.has-success {
  border-color: #28a745; }
  .form-control.has-success:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.text-primaryLight {
  color: #5641f7; }

.text-primaryDark {
  color: #171546; }

.text-black-0 {
  color: rgba(0, 0, 0, 0); }

.bg-black-0 {
  background-color: rgba(0, 0, 0, 0); }

.text-black-10 {
  color: rgba(0, 0, 0, 0.1); }

.bg-black-10 {
  background-color: rgba(0, 0, 0, 0.1); }

.text-black-20 {
  color: rgba(0, 0, 0, 0.2); }

.bg-black-20 {
  background-color: rgba(0, 0, 0, 0.2); }

.text-black-30 {
  color: rgba(0, 0, 0, 0.3); }

.bg-black-30 {
  background-color: rgba(0, 0, 0, 0.3); }

.text-black-40 {
  color: rgba(0, 0, 0, 0.4); }

.bg-black-40 {
  background-color: rgba(0, 0, 0, 0.4); }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5); }

.bg-black-50 {
  background-color: rgba(0, 0, 0, 0.5); }

.text-black-60 {
  color: rgba(0, 0, 0, 0.6); }

.bg-black-60 {
  background-color: rgba(0, 0, 0, 0.6); }

.text-black-70 {
  color: rgba(0, 0, 0, 0.7); }

.bg-black-70 {
  background-color: rgba(0, 0, 0, 0.7); }

.text-black-80 {
  color: rgba(0, 0, 0, 0.8); }

.bg-black-80 {
  background-color: rgba(0, 0, 0, 0.8); }

.text-black-90 {
  color: rgba(0, 0, 0, 0.9); }

.bg-black-90 {
  background-color: rgba(0, 0, 0, 0.9); }

.text-black-100 {
  color: black; }

.bg-black-100 {
  background-color: black; }

.edit-pcba {
  --primary: #2f2575;
  --primaryLight: #5641f7;
  --primaryDark: #171546;
  --secondary: #ec7b51;
  --secondaryLight: #ffa451; }
  .edit-pcba .bg-primary, .edit-pcba .account_table .hover-primary:hover, .account_table .edit-pcba .hover-primary:hover,
  .edit-pcba .btn-primary {
    background-color: #2f2575 !important; }
  .edit-pcba .bg-primary-light {
    background-color: #5641f7 !important; }
  .edit-pcba .bg-secondary,
  .edit-pcba .btn-secondary {
    background-color: #ec7b51 !important; }
  .edit-pcba .text-primary {
    color: #2f2575 !important; }
  .edit-pcba .text-secondary {
    color: #ec7b51 !important; }

.bg-pcba {
  background: #2f2575; }

.text-pcba {
  color: #2f2575; }

.text-secondary-pcba {
  color: #ec7b51; }

.text-secondary-light-pcba {
  color: #ffa451; }

.edit-wiring {
  --primary: #0fafab;
  --primaryLight: #2ccfcd;
  --primaryDark: #006f80;
  --secondary: #c73534;
  --secondaryLight: #ff4934; }
  .edit-wiring .bg-primary, .edit-wiring .account_table .hover-primary:hover, .account_table .edit-wiring .hover-primary:hover,
  .edit-wiring .btn-primary {
    background-color: #0fafab !important; }
  .edit-wiring .bg-primary-light {
    background-color: #2ccfcd !important; }
  .edit-wiring .bg-secondary,
  .edit-wiring .btn-secondary {
    background-color: #c73534 !important; }
  .edit-wiring .text-primary {
    color: #0fafab !important; }
  .edit-wiring .text-secondary {
    color: #c73534 !important; }

.bg-wiring {
  background: #0fafab; }

.text-wiring {
  color: #0fafab; }

.text-secondary-wiring {
  color: #c73534; }

.text-secondary-light-wiring {
  color: #ff4934; }

.edit-pcb {
  --primary: #00957a;
  --primaryLight: #00c161;
  --primaryDark: #004b3d;
  --secondary: #f1b400;
  --secondaryLight: #ffdb00; }
  .edit-pcb .bg-primary, .edit-pcb .account_table .hover-primary:hover, .account_table .edit-pcb .hover-primary:hover,
  .edit-pcb .btn-primary {
    background-color: #00957a !important; }
  .edit-pcb .bg-primary-light {
    background-color: #00c161 !important; }
  .edit-pcb .bg-secondary,
  .edit-pcb .btn-secondary {
    background-color: #f1b400 !important; }
  .edit-pcb .text-primary {
    color: #00957a !important; }
  .edit-pcb .text-secondary {
    color: #f1b400 !important; }

.bg-pcb {
  background: #00957a; }

.text-pcb {
  color: #00957a; }

.text-secondary-pcb {
  color: #f1b400; }

.text-secondary-light-pcb {
  color: #ffdb00; }

.edit-enclosure {
  --primary: #0077be;
  --primaryLight: #00a0fc;
  --primaryDark: #003dd5;
  --secondary: #00957a;
  --secondaryLight: #00c161; }
  .edit-enclosure .bg-primary, .edit-enclosure .account_table .hover-primary:hover, .account_table .edit-enclosure .hover-primary:hover,
  .edit-enclosure .btn-primary {
    background-color: #0077be !important; }
  .edit-enclosure .bg-primary-light {
    background-color: #00a0fc !important; }
  .edit-enclosure .bg-secondary,
  .edit-enclosure .btn-secondary {
    background-color: #00957a !important; }
  .edit-enclosure .text-primary {
    color: #0077be !important; }
  .edit-enclosure .text-secondary {
    color: #00957a !important; }

.bg-enclosure {
  background: #0077be; }

.text-enclosure {
  color: #0077be; }

.text-secondary-enclosure {
  color: #00957a; }

.text-secondary-light-enclosure {
  color: #00c161; }

.edit-coating {
  --primary: #c7215d;
  --primaryLight: #e6495d;
  --primaryDark: #951946;
  --secondary: #97bf0d;
  --secondaryLight: #aedd00; }
  .edit-coating .bg-primary, .edit-coating .account_table .hover-primary:hover, .account_table .edit-coating .hover-primary:hover,
  .edit-coating .btn-primary {
    background-color: #c7215d !important; }
  .edit-coating .bg-primary-light {
    background-color: #e6495d !important; }
  .edit-coating .bg-secondary,
  .edit-coating .btn-secondary {
    background-color: #97bf0d !important; }
  .edit-coating .text-primary {
    color: #c7215d !important; }
  .edit-coating .text-secondary {
    color: #97bf0d !important; }

.bg-coating {
  background: #c7215d; }

.text-coating {
  color: #c7215d; }

.text-secondary-coating {
  color: #97bf0d; }

.text-secondary-light-coating {
  color: #aedd00; }

.edit-xray {
  --primary: #c73534;
  --primaryLight: #ff4934;
  --primaryDark: #811b27;
  --secondary: #6e2367;
  --secondaryLight: #6e3da4; }
  .edit-xray .bg-primary, .edit-xray .account_table .hover-primary:hover, .account_table .edit-xray .hover-primary:hover,
  .edit-xray .btn-primary {
    background-color: #c73534 !important; }
  .edit-xray .bg-primary-light {
    background-color: #ff4934 !important; }
  .edit-xray .bg-secondary,
  .edit-xray .btn-secondary {
    background-color: #6e2367 !important; }
  .edit-xray .text-primary {
    color: #c73534 !important; }
  .edit-xray .text-secondary {
    color: #6e2367 !important; }

.bg-xray {
  background: #c73534; }

.text-xray {
  color: #c73534; }

.text-secondary-xray {
  color: #6e2367; }

.text-secondary-light-xray {
  color: #6e3da4; }

.edit-assembly {
  --primary: #97bf0d;
  --primaryLight: #aedd00;
  --primaryDark: #63b50d;
  --secondary: #2f2575;
  --secondaryLight: #5641f7; }
  .edit-assembly .bg-primary, .edit-assembly .account_table .hover-primary:hover, .account_table .edit-assembly .hover-primary:hover,
  .edit-assembly .btn-primary {
    background-color: #97bf0d !important; }
  .edit-assembly .bg-primary-light {
    background-color: #aedd00 !important; }
  .edit-assembly .bg-secondary,
  .edit-assembly .btn-secondary {
    background-color: #2f2575 !important; }
  .edit-assembly .text-primary {
    color: #97bf0d !important; }
  .edit-assembly .text-secondary {
    color: #2f2575 !important; }

.bg-assembly {
  background: #97bf0d; }

.text-assembly {
  color: #97bf0d; }

.text-secondary-assembly {
  color: #2f2575; }

.text-secondary-light-assembly {
  color: #5641f7; }

.edit-rework {
  --primary: #f1b400;
  --primaryLight: #ffdb00;
  --primaryDark: #f18f00;
  --secondary: #0077be;
  --secondaryLight: #00a0fc; }
  .edit-rework .bg-primary, .edit-rework .account_table .hover-primary:hover, .account_table .edit-rework .hover-primary:hover,
  .edit-rework .btn-primary {
    background-color: #f1b400 !important; }
  .edit-rework .bg-primary-light {
    background-color: #ffdb00 !important; }
  .edit-rework .bg-secondary,
  .edit-rework .btn-secondary {
    background-color: #0077be !important; }
  .edit-rework .text-primary {
    color: #f1b400 !important; }
  .edit-rework .text-secondary {
    color: #0077be !important; }

.bg-rework {
  background: #f1b400; }

.text-rework {
  color: #f1b400; }

.text-secondary-rework {
  color: #0077be; }

.text-secondary-light-rework {
  color: #00a0fc; }

.edit-engineering {
  --primary: #6e2367;
  --primaryLight: #6e3da4;
  --primaryDark: #531e67;
  --secondary: #00aeab;
  --secondaryLight: #2ccfcd; }
  .edit-engineering .bg-primary, .edit-engineering .account_table .hover-primary:hover, .account_table .edit-engineering .hover-primary:hover,
  .edit-engineering .btn-primary {
    background-color: #6e2367 !important; }
  .edit-engineering .bg-primary-light {
    background-color: #6e3da4 !important; }
  .edit-engineering .bg-secondary,
  .edit-engineering .btn-secondary {
    background-color: #00aeab !important; }
  .edit-engineering .text-primary {
    color: #6e2367 !important; }
  .edit-engineering .text-secondary {
    color: #00aeab !important; }

.bg-engineering {
  background: #6e2367; }

.text-engineering {
  color: #6e2367; }

.text-secondary-engineering {
  color: #00aeab; }

.text-secondary-light-engineering {
  color: #2ccfcd; }

.edit-supply {
  --primary: #1f6a90;
  --primaryLight: #1c8ac0;
  --primaryDark: #1f5875;
  --secondary: #ef9829;
  --secondaryLight: #ffb85c; }
  .edit-supply .bg-primary, .edit-supply .account_table .hover-primary:hover, .account_table .edit-supply .hover-primary:hover,
  .edit-supply .btn-primary {
    background-color: #1f6a90 !important; }
  .edit-supply .bg-primary-light {
    background-color: #1c8ac0 !important; }
  .edit-supply .bg-secondary,
  .edit-supply .btn-secondary {
    background-color: #ef9829 !important; }
  .edit-supply .text-primary {
    color: #1f6a90 !important; }
  .edit-supply .text-secondary {
    color: #ef9829 !important; }

.bg-supply {
  background: #1f6a90; }

.text-supply {
  color: #1f6a90; }

.text-secondary-supply {
  color: #ef9829; }

.text-secondary-light-supply {
  color: #ffb85c; }

.btn_engineering {
  background-color: var(--secondary);
  color: white; }

#main-navigation {
  font-size: 1.1rem; }
  #main-navigation a:hover {
    text-decoration: none; }
  #main-navigation .navbar-brand {
    padding: 0; }
    #main-navigation .navbar-brand img {
      height: 74px; }
  #main-navigation + *.projects {
    background-color: #f8f9fa; }
  #main-navigation + * > *:first-child {
    padding-top: 74px; }
    #main-navigation + * > *:first-child#content {
      max-width: 1620px;
      padding-top: 100px;
      flex: 1 1 auto !important; }
  #main-navigation .header-user-block {
    height: 100%;
    font-family: IntroCond;
    font-weight: 700; }
    #main-navigation .header-user-block > *.bg-primaryDark {
      border-left: 3px solid #2f2575; }
    #main-navigation .header-user-block > *,
    #main-navigation .header-user-block .header-user-block_btn {
      padding: 23px 15px;
      border-left: #171546 1px solid;
      height: 100%;
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase; }
      @media (min-width: 992px) {
        #main-navigation .header-user-block > *,
        #main-navigation .header-user-block .header-user-block_btn {
          padding: 23px; } }
      @media (min-width: 1200px) {
        #main-navigation .header-user-block > *,
        #main-navigation .header-user-block .header-user-block_btn {
          font-size: 15px;
          line-height: 15px; } }
      #main-navigation .header-user-block > * a:hover,
      #main-navigation .header-user-block .header-user-block_btn a:hover {
        color: inherit;
        margin-top: inherit;
        border-bottom: none;
        font-weight: inherit;
        text-decoration: none; }
      #main-navigation .header-user-block > * .icon,
      #main-navigation .header-user-block .header-user-block_btn .icon {
        color: #fff;
        font-size: 26px;
        line-height: 26px;
        vertical-align: sub; }
        @media (min-width: 992px) {
          #main-navigation .header-user-block > * .icon,
          #main-navigation .header-user-block .header-user-block_btn .icon {
            font-size: 24px;
            line-height: 24px; } }
    #main-navigation .header-user-block .header__user_menu .dropdown-menu {
      background: #171546;
      color: #fff;
      border-color: #fff;
      transform: translate(-33%, 25px);
      border-radius: 0; }
      #main-navigation .header-user-block .header__user_menu .dropdown-menu .dropdown-item {
        padding: 0.5rem;
        color: #fff; }
        #main-navigation .header-user-block .header__user_menu .dropdown-menu .dropdown-item i {
          width: 16px;
          text-align: center; }
        #main-navigation .header-user-block .header__user_menu .dropdown-menu .dropdown-item:hover {
          background: #2f2575; }
        #main-navigation .header-user-block .header__user_menu .dropdown-menu .dropdown-item.active {
          background: #ec7b51; }

.header_menu a:hover,
.user_menu a:hover {
  text-decoration: none; }

.header_nav1 {
  background-color: #2f2575;
  height: 74px; }

.header_nav2 {
  background-color: #fff;
  position: fixed;
  top: 74px;
  z-index: 3;
  width: 100%; }

.opaque {
  background-color: #f8f9fa;
  opacity: 0.9; }

.login_btn {
  border-right: 1px solid #171546;
  border-left: 1px solid #171546;
  height: 75px;
  padding: 5px 5px 5px 5px; }
  .login_btn span {
    color: white; }
  .login_btn #signup {
    border-left: 1px solid #171546; }
  .login_btn div:hover {
    background-color: #171546; }

.header_menu > * {
  font-family: IntroCond;
  cursor: pointer;
  margin: 4px 12px;
  padding: 8px 5px;
  color: #adb5bd; }
  .header_menu > *:hover, .header_menu > *.active {
    color: #2f2575;
    margin-bottom: 0;
    border-bottom: 4px solid #ec7b51;
    font-weight: 500; }

.language_dropdown {
  text-transform: uppercase; }
  .language_dropdown > .dropdown-toggle {
    font-size: 18px;
    line-height: 28px; }

.footer_white a {
  color: white; }

.footer_img {
  background-color: #2f2575; }

.subfooter_img {
  height: 24px; }

.should_reload {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ec7b51;
  text-align: center;
  padding: 5px;
  color: #fff;
  cursor: pointer; }

.factory_card__secondary {
  margin-top: 20px;
  height: 165px;
  width: 140px;
  position: absolute;
  top: 130px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; }

.home_modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000c;
  z-index: 1030; }
  .home_modal .carousel,
  .home_modal .carousel-inner,
  .home_modal .carousel-item {
    height: 100%; }
  .home_modal .carousel-control-next,
  .home_modal .carousel-control-prev {
    height: 50%;
    margin-top: 25vh; }
  .home_modal .carousel-control-next-icon,
  .home_modal .carousel-control-prev-icon {
    fill: #fff; }

.cookie {
  width: 100%;
  background-color: #2f2575;
  opacity: 0.9;
  position: fixed;
  bottom: 0px;
  z-index: 1; }
  .cookie span {
    font-size: 18px;
    font-weight: 500; }

.fonticon {
  display: block;
  margin: 1rem auto;
  width: 120px;
  height: 120px;
  background: #ec7b51;
  border-radius: 60px; }

.media div {
  width: 100%; }

.dropzone.file_list {
  border: 1px solid #e5e5e5;
  background-color: #f6f6f6;
  cursor: pointer;
  transition: all 0.4s ease;
  padding: 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .dropzone.file_list.dropzone_add-not_empty {
    padding: 0; }
  .dropzone.file_list .icon {
    margin-bottom: 10px; }
  .dropzone.file_list.dropzone-hover {
    background-color: #2f2575;
    color: #fff; }
    .dropzone.file_list.dropzone-hover .icon {
      color: #fff; }
    .dropzone.file_list.dropzone-hover .file_list {
      opacity: 0; }
  .dropzone.file_list .file_list {
    display: flex;
    flex-direction: column; }
    .dropzone.file_list .file_list .progress {
      border-radius: 0;
      background: none;
      height: initial;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; }
      .dropzone.file_list .file_list .progress .progress-bar {
        background-color: #ec7b51; }
    .dropzone.file_list .file_list .btn-group > *:first-child {
      text-align: left; }
    .dropzone.file_list .file_list .btn-group > *:last-child {
      flex-grow: 0; }

.dropzone.dropzone-hover {
  background: #2f2575;
  color: #fff; }
  .dropzone.dropzone-hover .icon {
    color: #fff; }

.dropzone.disabled {
  cursor: not-allowed; }

.picto {
  max-width: 50px;
  max-height: 50px; }

.icon.icon-md {
  font-size: 34px; }

.icon.icon-lg {
  font-size: 63px; }

.text-pcba-secondary {
  color: #ec7b51; }

.marketing_section {
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  min-height: 300px;
  position: relative;
  font-size: 1rem;
  overflow-x: auto; }
  @media (min-width: 576px) {
    .marketing_section {
      padding-top: 25px;
      padding-bottom: 25px; } }
  @media (min-width: 768px) {
    .marketing_section {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media (min-width: 992px) {
    .marketing_section {
      padding-top: 66.66667px;
      padding-bottom: 66.66667px; } }
  .marketing_section.bg-primary .marketing_section__title, .account_table .marketing_section.hover-primary:hover .marketing_section__title {
    color: #fff; }
    .marketing_section.bg-primary .marketing_section__title:after, .account_table .marketing_section.hover-primary:hover .marketing_section__title:after {
      content: "";
      border-bottom: 4px solid #5641f7; }
  .marketing_section.bg-secondary .marketing_section__title {
    color: #fff; }
    .marketing_section.bg-secondary .marketing_section__title:after {
      content: "";
      border-bottom: 4px solid #2f2575; }

.marketing_section-bgimage {
  background-size: cover;
  background-position: center; }

.marketing_section__title {
  font-family: MysteryTourSans;
  text-transform: lowercase;
  display: block;
  text-align: center;
  font-size: 1rem;
  line-height: 1.3;
  letter-spacing: 0.238rem;
  width: 100%;
  margin: 1rem auto; }
  @media (min-width: 576px) {
    .marketing_section__title {
      width: 90%; } }
  @media (min-width: 768px) {
    .marketing_section__title {
      width: 75%;
      margin: 0 auto 2rem;
      font-size: 1.2rem; } }
  @media (min-width: 992px) {
    .marketing_section__title {
      width: 50%;
      margin: 0 auto 4rem;
      font-size: 1.5rem; } }
  .marketing_section__title:after {
    content: "";
    border-bottom: 4px solid #ec7b51;
    width: 100px;
    bottom: 0;
    margin: 1rem auto;
    display: block; }

.marketing_section__subtitle {
  font-size: 1.2rem; }

.marketing_section__bottom_rounded {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 50px; }

.page_cover {
  padding-top: 0;
  background-color: #2f2575;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff; }
  .page_cover .container {
    padding-top: 1rem; }
    .page_cover .container h1 {
      text-align: left; }
    @media (min-width: 992px) {
      .page_cover .container {
        padding-top: 2rem; } }
    @media (min-width: 1200px) {
      .page_cover .container {
        padding-top: 5rem; } }

.marketing {
  padding-top: 66px; }
  .marketing .big_badge {
    background-color: #ec7b51;
    color: #fff;
    width: 2.66667rem;
    height: 2.66667rem;
    border-radius: 1.33333rem;
    display: block;
    text-align: center;
    padding: 0.66667rem;
    font-family: MysteryTourSans;
    font-size: 1rem;
    line-height: 1.7;
    font-weight: 600; }
  .marketing .between_steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 5.1rem;
    text-align: center; }
  .marketing .btn {
    border-radius: 30px;
    font-family: MysteryTourSans;
    text-transform: lowercase;
    font-size: 0.6375rem;
    letter-spacing: 0.1em;
    padding: 0.8rem 0.8rem 0.7rem 1.2rem; }
    .marketing .btn span {
      vertical-align: bottom;
      letter-spacing: 0.17rem; }
      .marketing .btn span::after {
        content: "\f105";
        font-family: "Font awesome 5 Free";
        color: #ec7b51;
        font-size: 1.02rem;
        line-height: 0.85rem;
        font-weight: 900;
        vertical-align: middle;
        margin-left: 0.7rem; }
  .marketing.marketing_page-cookies {
    padding-top: 0; }

.marketing_page-transition .cms_content,
.marketing_page-legal_notice .cms_content {
  margin-left: 8.33333%;
  max-width: 83.33333%; }

.last_news_widget {
  background: #5641f7;
  height: 78px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  padding: 0 0.5rem;
  font-size: 0.85rem;
  line-height: 1.02rem; }
  .last_news_widget img {
    height: 60%; }
  .last_news_widget .last_news_widget__news {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    .last_news_widget .last_news_widget__news .last_news_widget__news_title {
      font-weight: bold;
      text-transform: uppercase;
      line-height: 0.6rem;
      margin-bottom: 0.5rem;
      font-size: 1rem; }
    .last_news_widget .last_news_widget__news p {
      margin: 0; }
  .last_news_widget .last_news_widget__border {
    border-right: 1px solid #2f2575;
    height: 80%; }
  .last_news_widget i {
    font-size: 1.275rem; }

.engineeringtools_img img {
  width: 100%; }

ul.square_benefits {
  list-style: none;
  display: flex;
  padding: 0; }
  ul.square_benefits li {
    background: #ec7b51;
    width: 125px;
    height: 52px;
    margin: 0.5rem;
    box-shadow: 4px 5px 0 #2f2575;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1;
    text-align: center; }
    ul.square_benefits li .homecover__list_item {
      font-size: 20px; }

.price_cell {
  border-left: 1px solid #adb5bd;
  border-right: 1px solid #adb5bd;
  border-top: 1px solid #adb5bd;
  min-height: 45px;
  padding: 0.5rem 0;
  font-size: 19px;
  display: flex;
  align-items: center;
  text-decoration: none; }
  .price_cell > * {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
    .price_cell > *:first-child {
      text-align: right;
      padding-right: 0.5rem; }
    .price_cell > *:last-child {
      padding-left: 0.5rem; }

#cart {
  background: #f8f9fa; }

.cart_btn {
  border-radius: 25px;
  border-color: #ced4da;
  border: 1px solid #ced4da;
  font-size: 16px; }

@media (min-width: 992px) {
  .new_project:not(.new_project-masonry) {
    display: block; }
  .new_project.new_project-emslabs .square_product_link-engineering,
  .new_project.new_project-emslabs .square_product_link-emslabs {
    height: 66px; }
    .new_project.new_project-emslabs .square_product_link-engineering img,
    .new_project.new_project-emslabs .square_product_link-emslabs img {
      display: none; } }
  @media (min-width: 992px) and (min-width: 1200px) {
    .new_project.new_project-emslabs .square_product_link-engineering,
    .new_project.new_project-emslabs .square_product_link-emslabs {
      height: 76px; } }

.new_project .square_product_link {
  overflow: hidden;
  height: 124px;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  margin-bottom: 0.5rem; }
  .new_project .square_product_link.square_product_link-masonry {
    flex-grow: 1;
    min-width: 30%; }
  .new_project .square_product_link:not(.square_product_link-masonry) {
    flex: 0 0 50%;
    max-width: 50%;
    float: left;
    width: 100%; }
    @media (min-width: 992px) {
      .new_project .square_product_link:not(.square_product_link-masonry) {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-bottom: 1rem;
        height: 149px; } }
    @media (min-width: 1200px) {
      .new_project .square_product_link:not(.square_product_link-masonry) {
        height: 168px; } }
  .new_project .square_product_link a {
    display: block;
    color: #fff;
    text-align: center;
    padding: 6px;
    font-family: MysteryTourSans;
    text-transform: lowercase;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    word-break: break-word; }
    .new_project .square_product_link a img {
      max-height: 64px; }
    @media (min-width: 992px) {
      .new_project .square_product_link a {
        justify-content: space-around; }
        .new_project .square_product_link a img {
          max-height: 70px; } }
  .new_project .square_product_link.square_product_link-pcba a {
    background: #2f2575; }
  .new_project .square_product_link.square_product_link-wiring a {
    background: #0fafab; }
  .new_project .square_product_link.square_product_link-pcb a {
    background: #00957a; }
  .new_project .square_product_link.square_product_link-enclosure a {
    background: #0077be; }
  .new_project .square_product_link.square_product_link-coating a {
    background: #c7215d; }
  .new_project .square_product_link.square_product_link-xray a {
    background: #c73534; }
  .new_project .square_product_link.square_product_link-assembly a {
    background: #97bf0d; }
  .new_project .square_product_link.square_product_link-rework a {
    background: #f1b400; }
  .new_project .square_product_link.square_product_link-engineering a {
    background: #6e2367; }

.marketing_page-home {
  background-repeat: no-repeat;
  background-position: -21% 373px;
  background-size: 50%; }

#homecover {
  font-size: 1.275rem;
  line-height: 1.53rem;
  background: #fff;
  color: #2f2575; }
  @media (min-width: 992px) {
    #homecover {
      background: none; } }
  #homecover .homecover__products {
    font-size: 0.7rem;
    line-height: 1rem;
    padding: 1rem; }
    @media (min-width: 768px) {
      #homecover .homecover__products {
        padding-top: 0;
        width: 256px;
        padding-right: 0.5rem; } }
    @media (min-width: 992px) {
      #homecover .homecover__products {
        width: 50%;
        padding-right: 0; } }
    @media (min-width: 1200px) {
      #homecover .homecover__products {
        font-size: 1rem;
        line-height: 1.2rem;
        padding: 0 1rem; } }
  #homecover .homecover__text {
    background: #fffb; }
  #homecover h1 {
    font-family: MysteryTourSans;
    text-transform: lowercase; }

.background_white {
  background-color: white; }

.marketing_section.control_manufacturing .marketing_section__title {
  background: #fff8;
  padding: 1rem; }

@media (max-width: 991.98px) {
  .marketing_section.control_manufacturing .between_steps {
    display: none; } }

.control_manufacturing__step {
  background-color: #2f2575;
  color: #fff;
  padding-top: 1rem;
  text-align: left; }
  .control_manufacturing__step a {
    background-color: #5641f7;
    width: 100%;
    height: 50px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    font-size: 0.935rem; }

.control_manufacturing__step-2 ul {
  padding-bottom: 33px; }

.control_manufacturing__step_title {
  font-weight: 600;
  font-size: 1.105rem;
  line-height: 1.2;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: 1rem; }

.factoryProcess {
  text-align: center;
  background-color: #ff0000; }
  .factoryProcess img {
    background-color: red; }

.keyBenefits .keyBenefits_benefits > *:last-child .keyBenefit {
  border-bottom: none; }

.keyBenefits .keyBenefit {
  display: flex;
  padding: 1rem 0;
  border-bottom: 1px solid #0002;
  align-items: center; }
  .keyBenefits .keyBenefit img {
    max-width: 25%;
    margin-right: 1rem; }
  .keyBenefits .keyBenefit .keyBenefit__title {
    font-family: MysteryTourSans;
    text-transform: lowercase;
    color: #ec7b51; }
  .keyBenefits .keyBenefit .keyBenefit__text {
    font-size: 0.85rem;
    line-height: 1.19rem; }
    .keyBenefits .keyBenefit .keyBenefit__text p {
      margin-bottom: 0; }
  @media (min-width: 768px) {
    .keyBenefits .keyBenefit {
      align-items: center;
      text-align: center;
      flex-direction: column;
      border-bottom: none; }
      .keyBenefits .keyBenefit img {
        max-width: 70%;
        margin-right: 0;
        margin-bottom: 2rem; }
      .keyBenefits .keyBenefit .keyBenefit__title {
        margin-bottom: 0.5rem; }
      .keyBenefits .keyBenefit .keyBenefit__text {
        font-size: 1.02rem;
        line-height: 1.36rem; } }

.enclosure_table {
  background-color: #f6f6f6;
  border-left: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  height: 65px; }

.enclosure_selected {
  background-color: #00c067;
  color: white; }

.enclosure_select select {
  background-color: #f6f6f6;
  color: #00c067; }
  .enclosure_select select ::after {
    background-color: #f6f6f6;
    color: #00c067; }
  .enclosure_select select option {
    background-color: #f6f6f6;
    color: #00c067; }

.behind_scene {
  background-repeat: no-repeat;
  background-position: right 72%;
  background-size: 100%;
  position: relative;
  background-color: #2f2575;
  color: #fff;
  overflow: initial; }
  @media (min-width: 576px) {
    .behind_scene {
      background-position-y: 85%;
      background-size: 80%; } }
  @media (min-width: 992px) {
    .behind_scene {
      background-size: 60%; } }
  @media (min-width: 1200px) {
    .behind_scene {
      background-position-y: 50%;
      background-size: 50%; } }
  .behind_scene > div {
    height: 100%;
    padding: 0 1rem; }
  .behind_scene svg {
    position: absolute;
    bottom: 100%;
    width: 100%;
    height: 100px;
    padding-top: 50px;
    margin-left: -1rem; }
    .behind_scene svg path {
      fill: #2f2575; }
  .behind_scene .marketing_section__title {
    margin-top: 1rem; }
    @media (min-width: 992px) {
      .behind_scene .marketing_section__title {
        margin-bottom: 5rem; } }
    @media (min-width: 1200px) {
      .behind_scene .marketing_section__title {
        margin-bottom: 10rem; } }
    .behind_scene .marketing_section__title::after {
      border-color: #5641f7; }
  .behind_scene .behind_scene__gallery_wrapper {
    margin-bottom: 300px; }
    @media (min-width: 1200px) {
      .behind_scene .behind_scene__gallery_wrapper {
        margin-bottom: 150px; } }
  .behind_scene .behind_scene__gallery {
    margin-left: auto;
    max-width: 600px; }
  .behind_scene .behind_scene__text p {
    margin-top: 1rem;
    background: #ec7b51;
    color: #fff;
    padding: 1.8rem 3rem;
    font-size: 1.275rem;
    font-weight: 600; }
    @media (min-width: 576px) {
      .behind_scene .behind_scene__text p {
        margin-left: 60px; } }

.timeline {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  align-items: center;
  text-align: center; }
  .timeline .timeline__title {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%; }
    .timeline .timeline__title > * {
      min-width: 16px; }
    .timeline .timeline__title h5 {
      width: 60%;
      margin: 0;
      text-align: center; }
  .timeline .timeline__line {
    position: relative;
    display: none;
    margin: 1rem 0;
    flex-direction: column;
    align-items: center;
    text-align: left;
    background-color: #2f2575;
    justify-content: space-between;
    z-index: 0;
    width: 3px; }
    .timeline .timeline__line.timeline__line-show {
      display: flex; }
    .timeline .timeline__line .timeline__step {
      width: 100vw;
      margin: 0.5rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1; }
      .timeline .timeline__line .timeline__step:nth-child(2) {
        margin-top: 0;
        margin-bottom: 1rem;
        align-items: start; }
      .timeline .timeline__line .timeline__step:last-child {
        margin-top: 1rem;
        margin-bottom: 0;
        align-items: flex-end; }
      .timeline .timeline__line .timeline__step:first-child i, .timeline .timeline__line .timeline__step:last-child i {
        display: none; }
      .timeline .timeline__line .timeline__step:first-child .timeline__step_circle.timeline__step_circle-success, .timeline .timeline__line .timeline__step:last-child .timeline__step_circle.timeline__step_circle-success {
        background: #28a745; }
      .timeline .timeline__line .timeline__step:nth-child(odd) {
        flex-direction: row-reverse;
        text-align: right; }
        .timeline .timeline__line .timeline__step:nth-child(odd) .timeline__step_title {
          flex-direction: row-reverse; }
      .timeline .timeline__line .timeline__step .timeline__step_content {
        width: 45%;
        padding: 0 0.5rem;
        flex-shrink: 0;
        min-height: 1rem; }
        .timeline .timeline__line .timeline__step .timeline__step_content p {
          margin: 0; }
        .timeline .timeline__line .timeline__step .timeline__step_content .timeline__step_title {
          word-break: break-word;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: start; }
          .timeline .timeline__line .timeline__step .timeline__step_content .timeline__step_title span {
            flex-shrink: 1; }
          .timeline .timeline__line .timeline__step .timeline__step_content .timeline__step_title i {
            margin: 0 0.5rem; }
        .timeline .timeline__line .timeline__step .timeline__step_content .timeline__step_text {
          display: none;
          font-size: 0.9rem; }
          .timeline .timeline__line .timeline__step .timeline__step_content .timeline__step_text.timeline__step_text-show {
            display: inline; }
      .timeline .timeline__line .timeline__step .timeline__step_circle {
        width: 24px;
        height: 24px;
        background: #fff;
        flex-shrink: 0;
        border: 1px solid #2f2575;
        border-radius: 100%;
        padding: 4px; }
        .timeline .timeline__line .timeline__step .timeline__step_circle .timeline__step_dot {
          width: 100%;
          height: 100%;
          background: #2f2575;
          border-radius: 100%; }
        .timeline .timeline__line .timeline__step .timeline__step_circle.timeline__step_circle-success {
          border-color: #28a745; }
          .timeline .timeline__line .timeline__step .timeline__step_circle.timeline__step_circle-success .timeline__step_dot {
            background: #28a745; }
  @media (min-width: 576px) {
    .timeline .timeline__line .timeline__step .timeline__step_content .timeline__step_title i {
      display: none; }
    .timeline .timeline__line .timeline__step .timeline__step_content .timeline__step_text {
      display: inline; } }
  @media (min-width: 768px) {
    .timeline .timeline__line .timeline__step .timeline__step_content {
      width: 35%; } }
  @media (max-width: 991.98px) {
    .timeline .timeline__line .bg-success {
      width: 3px !important; } }
  @media (min-width: 992px) {
    .timeline {
      flex-direction: column;
      margin-top: 100px; }
      .timeline .timeline__line {
        display: flex;
        flex-direction: row;
        height: 3px;
        width: 100%; }
        .timeline .timeline__line .bg-success {
          height: 3px !important; }
        .timeline .timeline__line .timeline__step {
          width: 24px;
          transform: rotate(-45deg); }
          .timeline .timeline__line .timeline__step:nth-child(2), .timeline .timeline__line .timeline__step:last-child {
            margin: 0;
            width: 14px; }
          .timeline .timeline__line .timeline__step:nth-child(odd) {
            flex-direction: row;
            text-align: left; }
            .timeline .timeline__line .timeline__step:nth-child(odd) .timeline__step_title {
              flex-direction: row; }
          .timeline .timeline__line .timeline__step:hover .timeline__step_circle {
            padding: 0;
            border: none; }
          .timeline .timeline__line .timeline__step:hover .timeline__step_content .timeline__step_title {
            font-weight: 500; }
          .timeline .timeline__line .timeline__step .timeline__step_content {
            width: 125px; }
            .timeline .timeline__line .timeline__step .timeline__step_content .timeline__step_title {
              font-weight: 400;
              font-size: 0.75rem; }
            .timeline .timeline__line .timeline__step .timeline__step_content .timeline__step_text {
              display: none; }
              .timeline .timeline__line .timeline__step .timeline__step_content .timeline__step_text.timeline__step_text-show {
                display: none; }
      .timeline .timeline__title {
        margin: 1rem 0; }
        .timeline .timeline__title i {
          display: none; } }
  @media (min-width: 1200px) {
    .timeline .timeline__line .timeline__step .timeline__step_content {
      width: 150px; }
      .timeline .timeline__line .timeline__step .timeline__step_content .timeline__step_title {
        font-size: 0.9rem; } }

.manufacturing_lines .manufacturing_lines__product {
  display: flex;
  margin: 1rem 0;
  border-bottom: 1px solid #0002; }
  .manufacturing_lines .manufacturing_lines__product:last-child {
    border: none; }
  .manufacturing_lines .manufacturing_lines__product > a {
    display: none; }

@media (min-width: 992px) {
  .manufacturing_lines .manufacturing_lines__product {
    margin: 4rem 0;
    border: none; }
    .manufacturing_lines .manufacturing_lines__product a {
      display: block; } }

@media (min-width: 1200px) {
  .manufacturing_lines .manufacturing_lines__product {
    margin: 5rem 0; } }

.bg-primaryDark {
  background-color: #171546; }

.bg-primaryLight {
  background-color: #5641f7; }

.factoryCover {
  background-color: #2f2575; }

.ressourcesCover {
  padding-bottom: 25px; }

.ressources_input {
  max-width: 670px;
  height: 50px; }
  .ressources_input input::placeholder {
    color: white; }

.factory_image img {
  width: 100px; }

.factory_btn .btn {
  color: #ec7b51;
  border-color: #fff;
  background: rgba(23, 21, 70, 0.6); }
  .factory_btn .btn span::after {
    color: #fff; }

.flex-parentFactory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  width: 100%;
  height: 100%;
  margin-bottom: 50px; }

.input-flex-containerFactory {
  display: block;
  top: 50%;
  height: 3px;
  background-color: #3d3d88;
  width: space-between;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  max-width: 1000px;
  position: relative;
  z-index: 0; }

.startEndFacrory {
  width: 17px;
  height: 17px;
  background-color: #3d3d88;
  position: relative;
  border-radius: 50%; }

.marketing_page-pcba {
  background-repeat: no-repeat;
  background-position: -21% 373px;
  background-size: 50%; }

.product_line-pcba h5 {
  color: #2f2575; }

.product_line-pcba .timeline__step .timeline__step_circle {
  border-color: #2f2575 !important; }
  .product_line-pcba .timeline__step .timeline__step_circle .timeline__step_dot {
    background: #2f2575 !important; }

.timeline.product_line-pcba .timeline__step_circle {
  border-color: #2f2575; }

.marketing_page-wiring {
  background-repeat: no-repeat;
  background-position: -21% 373px;
  background-size: 50%; }

.product_line-wiring h5 {
  color: #0fafab; }

.product_line-wiring .timeline__step .timeline__step_circle {
  border-color: #0fafab !important; }
  .product_line-wiring .timeline__step .timeline__step_circle .timeline__step_dot {
    background: #0fafab !important; }

.timeline.product_line-wiring .timeline__step_circle {
  border-color: #0fafab; }

.marketing_page-pcb {
  background-repeat: no-repeat;
  background-position: -21% 373px;
  background-size: 50%; }

.product_line-pcb h5 {
  color: #00957a; }

.product_line-pcb .timeline__step .timeline__step_circle {
  border-color: #00957a !important; }
  .product_line-pcb .timeline__step .timeline__step_circle .timeline__step_dot {
    background: #00957a !important; }

.timeline.product_line-pcb .timeline__step_circle {
  border-color: #00957a; }

.marketing_page-enclosure {
  background-repeat: no-repeat;
  background-position: -21% 373px;
  background-size: 50%; }

.product_line-enclosure h5 {
  color: #0077be; }

.product_line-enclosure .timeline__step .timeline__step_circle {
  border-color: #0077be !important; }
  .product_line-enclosure .timeline__step .timeline__step_circle .timeline__step_dot {
    background: #0077be !important; }

.timeline.product_line-enclosure .timeline__step_circle {
  border-color: #0077be; }

.marketing_page-coating {
  background-repeat: no-repeat;
  background-position: -21% 373px;
  background-size: 50%; }

.product_line-coating h5 {
  color: #c7215d; }

.product_line-coating .timeline__step .timeline__step_circle {
  border-color: #c7215d !important; }
  .product_line-coating .timeline__step .timeline__step_circle .timeline__step_dot {
    background: #c7215d !important; }

.timeline.product_line-coating .timeline__step_circle {
  border-color: #c7215d; }

.marketing_page-xray {
  background-repeat: no-repeat;
  background-position: -21% 373px;
  background-size: 50%; }

.product_line-xray h5 {
  color: #c73534; }

.product_line-xray .timeline__step .timeline__step_circle {
  border-color: #c73534 !important; }
  .product_line-xray .timeline__step .timeline__step_circle .timeline__step_dot {
    background: #c73534 !important; }

.timeline.product_line-xray .timeline__step_circle {
  border-color: #c73534; }

.marketing_page-assembly {
  background-repeat: no-repeat;
  background-position: -21% 373px;
  background-size: 50%; }

.product_line-assembly h5 {
  color: #97bf0d; }

.product_line-assembly .timeline__step .timeline__step_circle {
  border-color: #97bf0d !important; }
  .product_line-assembly .timeline__step .timeline__step_circle .timeline__step_dot {
    background: #97bf0d !important; }

.timeline.product_line-assembly .timeline__step_circle {
  border-color: #97bf0d; }

.marketing_page-rework {
  background-repeat: no-repeat;
  background-position: -21% 373px;
  background-size: 50%; }

.product_line-rework h5 {
  color: #f1b400; }

.product_line-rework .timeline__step .timeline__step_circle {
  border-color: #f1b400 !important; }
  .product_line-rework .timeline__step .timeline__step_circle .timeline__step_dot {
    background: #f1b400 !important; }

.timeline.product_line-rework .timeline__step_circle {
  border-color: #f1b400; }

.marketing_page-engineering {
  background-repeat: no-repeat;
  background-position: -21% 373px;
  background-size: 50%; }

.product_line-engineering h5 {
  color: #6e2367; }

.product_line-engineering .timeline__step .timeline__step_circle {
  border-color: #6e2367 !important; }
  .product_line-engineering .timeline__step .timeline__step_circle .timeline__step_dot {
    background: #6e2367 !important; }

.timeline.product_line-engineering .timeline__step_circle {
  border-color: #6e2367; }

.manufacturingLinesStep {
  margin-top: 50px; }

.spanFactory {
  visibility: visible;
  position: absolute;
  content: attr(data-info);
  top: -65px;
  width: 100px;
  transform: translateX(-5px) rotateZ(-45deg);
  text-align: center;
  font-size: 12px;
  text-indent: -10px; }

@media (min-width: 1250px) {
  .input::before {
    left: -70.83333px; }
  .input::after {
    right: -70.83333px; } }

.factory_prizes {
  text-align: center;
  line-height: 1; }
  .factory_prizes .factory_prizes_text {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem; }
    .factory_prizes .factory_prizes_text p {
      font-weight: 500;
      line-height: 1; }
  .factory_prizes .factory_prizes__prizes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .factory_prizes .factory_prizes__prizes .factory_prizes__prize {
      max-width: 50%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center; }
      .factory_prizes .factory_prizes__prizes .factory_prizes__prize .prize_icon {
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .factory_prizes .factory_prizes__prizes .factory_prizes__prize .prize_icon img {
          max-height: 100%; }
      .factory_prizes .factory_prizes__prizes .factory_prizes__prize .prize_text {
        display: none; }
        @media (min-width: 576px) {
          .factory_prizes .factory_prizes__prizes .factory_prizes__prize .prize_text {
            display: block; } }
      .factory_prizes .factory_prizes__prizes .factory_prizes__prize .prize_link {
        text-align: center; }
        .factory_prizes .factory_prizes__prizes .factory_prizes__prize .prize_link .btn {
          font-family: IntroCond;
          font-size: 0.9rem;
          font-weight: 500; }
  @media (min-width: 576px) {
    .factory_prizes .factory_prizes__prizes {
      margin: 0 -0.5rem; }
      .factory_prizes .factory_prizes__prizes .factory_prizes__prize {
        width: 265px;
        border: grey solid 1px;
        margin: 0.5rem; }
        .factory_prizes .factory_prizes__prizes .factory_prizes__prize .prize_icon {
          margin: 1rem 0; }
          .factory_prizes .factory_prizes__prizes .factory_prizes__prize .prize_icon img {
            max-width: 235px;
            max-height: 150px; }
        .factory_prizes .factory_prizes__prizes .factory_prizes__prize .prize_text {
          line-height: 1.2;
          margin: 1rem 0;
          font-weight: 500; }
        .factory_prizes .factory_prizes__prizes .factory_prizes__prize .prize_link {
          margin: 1rem 0; } }

.factoryProductLogo {
  width: 70px;
  height: 70px; }

.factoryProductLogo-pcba {
  background-color: #2f2575;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 12px;
  border: 3px solid #fff; }
  .factoryProductLogo-pcba:hover {
    box-shadow: 3px 3px 10px #0004; }

.factoryProductLogo-wiring {
  background-color: #0fafab;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 12px;
  border: 3px solid #fff; }
  .factoryProductLogo-wiring:hover {
    box-shadow: 3px 3px 10px #0004; }

.factoryProductLogo-pcb {
  background-color: #00957a;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 12px;
  border: 3px solid #fff; }
  .factoryProductLogo-pcb:hover {
    box-shadow: 3px 3px 10px #0004; }

.factoryProductLogo-enclosure {
  background-color: #0077be;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 12px;
  border: 3px solid #fff; }
  .factoryProductLogo-enclosure:hover {
    box-shadow: 3px 3px 10px #0004; }

.factoryProductLogo-coating {
  background-color: #c7215d;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 12px;
  border: 3px solid #fff; }
  .factoryProductLogo-coating:hover {
    box-shadow: 3px 3px 10px #0004; }

.factoryProductLogo-xray {
  background-color: #c73534;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 12px;
  border: 3px solid #fff; }
  .factoryProductLogo-xray:hover {
    box-shadow: 3px 3px 10px #0004; }

.factoryProductLogo-assembly {
  background-color: #97bf0d;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 12px;
  border: 3px solid #fff; }
  .factoryProductLogo-assembly:hover {
    box-shadow: 3px 3px 10px #0004; }

.factoryProductLogo-rework {
  background-color: #f1b400;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 12px;
  border: 3px solid #fff; }
  .factoryProductLogo-rework:hover {
    box-shadow: 3px 3px 10px #0004; }

.factoryProductLogo-engineering {
  background-color: #6e2367;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 12px;
  border: 3px solid #fff; }
  .factoryProductLogo-engineering:hover {
    box-shadow: 3px 3px 10px #0004; }

.factory_hover a:hover {
  transform: scale(1.3); }

.key_figures {
  text-align: left;
  background-color: #312972;
  font-size: 1rem;
  color: white;
  list-style-image: url("/icon/check.svg"); }
  .key_figures b {
    color: #ec7b51; }
  .key_figures .key_figures_figure {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #0008;
    margin-bottom: 1rem; }
    .key_figures .key_figures_figure.key_figures_figure-pcba .key_figures_figure_logo > div:not(.h6) > div {
      background: #2f2575; }
    .key_figures .key_figures_figure.key_figures_figure-wiring .key_figures_figure_logo > div:not(.h6) > div {
      background: #0fafab; }
    .key_figures .key_figures_figure.key_figures_figure-pcb .key_figures_figure_logo > div:not(.h6) > div {
      background: #00957a; }
    .key_figures .key_figures_figure.key_figures_figure-enclosure .key_figures_figure_logo > div:not(.h6) > div {
      background: #0077be; }
    .key_figures .key_figures_figure.key_figures_figure-coating .key_figures_figure_logo > div:not(.h6) > div {
      background: #c7215d; }
    .key_figures .key_figures_figure.key_figures_figure-xray .key_figures_figure_logo > div:not(.h6) > div {
      background: #c73534; }
    .key_figures .key_figures_figure.key_figures_figure-assembly .key_figures_figure_logo > div:not(.h6) > div {
      background: #97bf0d; }
    .key_figures .key_figures_figure.key_figures_figure-rework .key_figures_figure_logo > div:not(.h6) > div {
      background: #f1b400; }
    .key_figures .key_figures_figure.key_figures_figure-engineering .key_figures_figure_logo > div:not(.h6) > div {
      background: #6e2367; }
    .key_figures .key_figures_figure .key_figures_figure_logo {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      margin-bottom: 1rem; }
      .key_figures .key_figures_figure .key_figures_figure_logo > div:not(.h6) {
        border-radius: 50%;
        border: 1px solid #fff;
        padding: 2px;
        margin-right: 1rem; }
        .key_figures .key_figures_figure .key_figures_figure_logo > div:not(.h6) > div {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          padding: 10px;
          text-align: center;
          display: flex;
          justify-content: center; }
          .key_figures .key_figures_figure .key_figures_figure_logo > div:not(.h6) > div > img {
            max-width: 44px;
            max-height: 44px; }
      .key_figures .key_figures_figure .key_figures_figure_logo h6 {
        margin: 0 auto 0 0; }
      @media (min-width: 992px) {
        .key_figures .key_figures_figure .key_figures_figure_logo {
          flex-direction: column;
          justify-content: start;
          width: 150px;
          text-align: center; }
          .key_figures .key_figures_figure .key_figures_figure_logo > div {
            margin-right: 0;
            margin-bottom: 1rem; }
            .key_figures .key_figures_figure .key_figures_figure_logo > div > div {
              width: 72px;
              height: 72px; }
              .key_figures .key_figures_figure .key_figures_figure_logo > div > div > img {
                max-width: 52px;
                max-height: 52px; } }
    .key_figures .key_figures_figure .key_figures_capabilities {
      width: 300px;
      margin: 1rem; }
      .key_figures .key_figures_figure .key_figures_capabilities .capabilities_title {
        font-size: 1rem;
        border: none;
        margin: 0; }
      .key_figures .key_figures_figure .key_figures_capabilities li {
        font-size: 1rem !important;
        line-height: 1; }

.KeyFigureTitle {
  text-transform: uppercase;
  color: white;
  font-weight: 700; }

.KeyFigureLogoDisp {
  width: 100%;
  height: 100%;
  border-radius: 50%; }

.KeyFigureSeparator {
  border-bottom: 1px solid black; }

.KeyFigureIconTitle {
  margin-top: 10px;
  text-transform: uppercase;
  text-align: center; }

.start_project {
  display: none; }
  .start_project a {
    background-color: white; }
  @media (min-width: 992px) {
    .start_project {
      display: block; } }

.my-custom-popover .popover {
  max-width: 600px; }

.stocks .stocks_text {
  color: #ec7b51;
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
  margin: 1rem 0;
  display: flex;
  justify-content: center; }
  .stocks .stocks_text p {
    max-width: 360px; }

.stocks .stocks_stocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 -0.5rem; }
  .stocks .stocks_stocks .stocks_stock {
    max-width: 155px;
    margin: 0.5rem;
    display: flex;
    flex-direction: column-reverse; }
    .stocks .stocks_stocks .stocks_stock .stock_img {
      background: #2f2575; }
    .stocks .stocks_stocks .stocks_stock .stock_title {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1;
      text-transform: uppercase; }

@media (min-width: 992px) {
  .stocks .stocks_text {
    font-size: 1.2rem; }
    .stocks .stocks_text p {
      max-width: 500px; }
  .stocks .stocks_stocks .stocks_stock {
    flex-direction: column;
    text-align: center; }
    .stocks .stocks_stocks .stocks_stock .stock_title {
      margin-top: 1rem; } }

.StockText {
  color: #ec7b51;
  padding-bottom: 45px;
  text-align: center;
  font-size: 25px; }

.StockSquare {
  width: 198px;
  height: 198px;
  background-color: #3d3d88; }

.Stockh6 {
  text-align: center;
  padding-top: 20px; }

.norms {
  text-align: center;
  line-height: 1; }
  .norms .norms_text {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem; }
    .norms .norms_text p {
      font-weight: 500;
      line-height: 1; }
  .norms .norms_norms {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .norms .norms_norms .norms_norm {
      max-width: 50%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center; }
      .norms .norms_norms .norms_norm .norm_icon {
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .norms .norms_norms .norms_norm .norm_icon img {
          max-height: 100%; }
      .norms .norms_norms .norms_norm .norm_text {
        display: none; }
        @media (min-width: 576px) {
          .norms .norms_norms .norms_norm .norm_text {
            display: block; } }
      .norms .norms_norms .norms_norm .norm_link {
        text-align: center; }
        .norms .norms_norms .norms_norm .norm_link .btn {
          font-family: IntroCond;
          font-size: 0.9rem;
          font-weight: 500; }
      .norms .norms_norms .norms_norm .norm_info {
        display: none;
        height: 100px;
        width: 100%;
        border-top: 1px solid #0002;
        padding-top: 1rem;
        align-items: center; }
        .norms .norms_norms .norms_norm .norm_info > p {
          margin: 0;
          width: 100%; }
        .norms .norms_norms .norms_norm .norm_info ul {
          text-align: left; }
        @media (min-width: 576px) {
          .norms .norms_norms .norms_norm .norm_info {
            display: flex; } }
  @media (min-width: 576px) {
    .norms .norms_norms {
      margin: 0 -0.5rem; }
      .norms .norms_norms .norms_norm {
        max-width: 240px;
        border: grey solid 1px;
        margin: 0.5rem; }
        .norms .norms_norms .norms_norm .norm_icon {
          margin: 1rem 0; }
          .norms .norms_norms .norms_norm .norm_icon img {
            max-width: 150px;
            max-height: 150px; }
        .norms .norms_norms .norms_norm .norm_text {
          line-height: 1.2;
          margin: 1rem 0;
          font-weight: 500; }
        .norms .norms_norms .norms_norm .norm_link {
          margin: 1rem 0; } }

.Normscol {
  height: 550px; }

.NormsInfo {
  border-top: 0.5px grey solid;
  height: 24%;
  font-size: 95%;
  color: grey; }

.NormsDivIcon {
  height: 25%; }

.NomrsIcon {
  height: 100%;
  align-items: center; }

.NormsText {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  height: 45%;
  padding-top: 15%; }

.NormsLink {
  height: 10%;
  text-align: center;
  padding-top: 5%; }

.NormsCertificat {
  height: 45%;
  max-width: 92px;
  margin-top: 7%;
  align-items: center; }

.normsBtn {
  display: inline-block;
  font-weight: 600;
  color: #2f2575;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 30px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600; }

.Normsbtn-outline-primary {
  border-color: #f37a58; }

.pricingExperience_Square {
  width: 400px;
  min-height: 170px;
  background-color: #3d3d88;
  margin-bottom: 10px;
  margin-left: 15%; }

.pricingExperience_Title {
  font-weight: 500;
  font-size: 18px;
  padding-top: 5px; }

.pricingExperience_Text {
  margin-left: 10px; }

.pricingExperienceColor .big_badge {
  background-color: #5242f1; }

.pricingOptimization {
  background-color: #312972; }
  .pricingOptimization .marketing_section__title {
    color: white; }

.pricingOptimizationImg {
  border: 10px solid #5242f1; }

.pricingOptimizationText {
  color: #ec7b51;
  font-size: 25px;
  margin-bottom: 30px;
  padding-left: 15%;
  padding-right: 15%;
  font-weight: 600;
  text-align: center; }

.pricingCover {
  width: 540px; }

.contact_col {
  width: 50%; }

.contactcover {
  background-size: cover;
  background-color: #2f2575; }

.contact_form ::placeholder {
  color: #2f2575;
  font-size: 15px; }

.contactCapcha ::placeholder {
  color: grey;
  font-size: 15px;
  font-style: italic; }

.faq_icon img {
  width: 20px; }

.faq_question {
  background-color: rgba(0, 0, 0, 0.03); }
  .faq_question .faq_question .h5 {
    margin: 0; }
  .faq_question .faq_question:hover {
    background-color: #2f2575;
    color: #fff;
    cursor: pointer; }
    .faq_question .faq_question:hover i {
      color: white !important; }
    .faq_question .faq_question:hover button {
      color: white; }

.faq_img {
  padding-left: 20px;
  border-left: 1px solid #2f2575;
  color: #6c757d;
  font-size: 14px; }
  .faq_img img {
    width: 100%; }

.press_downlaod img {
  height: 60px; }

.press_card {
  color: #2f2575; }
  .press_card span {
    font-size: 16px;
    font-weight: 600; }
  .press_card p {
    font-size: 11px; }

.press_size {
  max-height: 400px; }

.fil_ariane {
  font-size: 15px; }

@media (min-width: 992px) {
  .blog_sidebar {
    position: fixed;
    width: initial; } }

.ressources_card_all {
  background-color: #e9ecef;
  border: 1px solid #e9ecef; }

.ressources_card {
  border-bottom: 1px solid #a8a9a9;
  padding-bottom: 5px;
  padding-top: 10px;
  margin-right: 10px; }
  .ressources_card span {
    font-weight: 600;
    font-size: 19px; }
  .ressources_card div {
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 1.25rem;
    padding-left: 10px;
    padding-right: 10px; }

.ressources_intro {
  font-size: 14px;
  color: gray; }

.ressources_arrow {
  background-color: #b6b6b6; }
  .ressources_arrow img {
    width: 100%; }

.ressources_files {
  background-color: #ec7b51;
  padding-bottom: 2px;
  padding-top: 2px;
  font-size: 15px; }
  .ressources_files img {
    height: 30px; }

.ressources_aside {
  border-bottom: solid 1px #a8a9a9; }

.ressources_card_strong {
  font-size: 18px; }

.ressources_card__img {
  max-height: 100%;
  max-width: 100%; }

.ressources_card__logo {
  height: 50px; }

.ressources_card_product {
  font-size: 18px; }

.ressources_category {
  font-size: 15px; }

.ressources_img {
  height: 100%; }

.blog_img {
  max-width: 100%; }

.img_category {
  position: absolute;
  top: 116px;
  left: 0; }

.blog .blog_blogs,
.ressources_article .blog_blogs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .blog .blog_blogs .blog_blogs_blog,
  .ressources_article .blog_blogs .blog_blogs_blog {
    margin: 0 0.5rem;
    width: 190px; }

.blog_card__img_wrapper {
  width: "100%";
  height: 190px;
  background: #adb5bd;
  display: flex;
  align-items: center;
  justify-content: center; }

.blog_card__img {
  max-width: 100%;
  max-height: 100%; }

.blog_category {
  background-color: #2f2575;
  text-transform: uppercase;
  font-weight: 600;
  padding: 6px 12px 5px 12px; }
  .blog_category span {
    font-size: 15px; }

.blog_title {
  border-bottom: 1px solid #a8a9a9; }
  .blog_title span {
    font-size: 30px;
    font-weight: 600; }

.blog_article {
  font-size: 15px;
  text-align: justify;
  width: 100%; }

.ressources_col {
  max-width: 32.33%; }
  .ressources_col a:hover {
    text-decoration: none; }

.ressources_article h4 {
  font-size: 20px;
  align-items: left; }

.ressources_article p {
  font-size: 15px; }

.cms_content {
  width: 100%;
  font-size: 18px; }
  .cms_content h1,
  .cms_content h2,
  .cms_content h3,
  .cms_content h4,
  .cms_content h5,
  .cms_content h6 {
    color: #ec7b51;
    font-family: IntroCond;
    text-transform: none;
    margin: 3rem 0 0.5rem; }
  .cms_content .image {
    display: block; }
    .cms_content .image.image-style-align-center {
      max-width: 50%;
      margin-left: auto;
      margin-right: auto; }
    .cms_content .image.image-style-align-left {
      float: left;
      max-width: 50%; }
    .cms_content .image.image-style-align-right {
      float: right;
      max-width: 50%; }

.ressources_searchBar {
  padding-right: 400px; }

.ressources_dl {
  background-color: #e9ecef; }
  .ressources_dl li {
    font-size: 17px;
    font-weight: 600; }
  .ressources_dl span {
    text-align: center; }
  .ressources_dl img {
    height: 50px; }

.ressources_pdf img {
  height: 60px; }

.err_bom_assembly {
  background-color: #ec7b51;
  opacity: 0.92;
  color: white;
  position: fixed;
  width: 43%;
  height: 65px;
  font-size: 25px;
  font-weight: 600; }

.warn_bom_assembly {
  background-color: #5641f7;
  opacity: 0.92;
  color: white;
  position: fixed;
  width: 43%;
  height: 65px;
  font-size: 25px;
  font-weight: 600; }

.ressources_card_color-pcba {
  background-color: #2f2575; }

.ressources_card_color-wiring {
  background-color: #0fafab; }

.ressources_card_color-pcb {
  background-color: #00957a; }

.ressources_card_color-enclosure {
  background-color: #0077be; }

.ressources_card_color-coating {
  background-color: #c7215d; }

.ressources_card_color-xray {
  background-color: #c73534; }

.ressources_card_color-assembly {
  background-color: #97bf0d; }

.ressources_card_color-rework {
  background-color: #f1b400; }

.ressources_card_color-engineering {
  background-color: #6e2367; }

.blog_content {
  background-color: #eeeeee; }
  .blog_content span {
    font-size: 18px;
    font-weight: 800;
    line-height: 1.2; }
  .blog_content p {
    font-size: 13px;
    color: #808080; }

.map {
  background-repeat: no-repeat;
  background-position: right 100%;
  background-size: 95%;
  min-height: 810px;
  position: relative;
  background-color: #db7757;
  color: #fff; }
  .map svg {
    position: absolute;
    bottom: 100%;
    width: 100%;
    height: 50px; }
    .map svg path {
      fill: #db7757; }
  .map .map__gallery {
    margin-left: auto;
    max-width: 600px; }
  .map .map__text p {
    vertical-align: middle;
    background: #3a2a73;
    color: #fff;
    padding: 10px 15px;
    font-weight: 600; }
  @media (min-width: 576px) {
    .map {
      background-position: right 100%;
      background-size: 75%;
      min-height: 900px; } }
  @media (min-width: 768px) {
    .map {
      background-position: right 80%;
      background-size: 50%;
      min-height: 1010px; }
      .map .map__text p {
        margin-top: 15%;
        margin-right: 54%;
        font-size: 25px;
        text-align: right; } }
  @media (min-width: 992px) {
    .map {
      background-position: right 60%;
      background-size: 55%;
      min-height: 940px; } }

.map_contest {
  height: 400px;
  width: 300px;
  position: absolute;
  top: 30px;
  right: 40px;
  background-color: #2f2575; }
  .map_contest img {
    width: 100%; }

.map_contactezNous {
  background-color: #2f2575;
  text-align: right;
  padding: 1rem; }
  @media (min-width: 992px) {
    .map_contactezNous {
      padding-right: 20px;
      padding-top: 20px;
      font-size: 25px;
      height: 500px;
      width: 450px;
      position: absolute;
      top: 250px;
      left: 0px; }
      .map_contactezNous h4 {
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 2px;
        border-bottom: solid 1px white; } }

.map_contact {
  position: absolute;
  top: 726px;
  left: 1040px; }

.map_btn {
  background-color: #2f2575;
  color: white;
  text-align: center;
  border-radius: 25px; }

.map_btn:hover {
  background-color: white;
  color: #2f2575;
  border: 1px solid #171546; }

.map_gallery {
  display: flex;
  flex-direction: column;
  justify-content: space-around; }
  .map_gallery div {
    width: 50%;
    height: 50%;
    margin-top: 50px; }
    .map_gallery div img {
      max-width: 100%;
      max-height: 100%; }

.product_modal {
  z-index: 1031;
  background-color: #000c; }

.helpTile {
  background: #2f2575; }
  .helpTile .marketing_section__title {
    color: white; }
  .helpTile span {
    color: white;
    font-size: 20px;
    text-align: center; }
  .helpTile h6 {
    color: #ec7b51;
    font-size: 20px; }
  .helpTile p {
    text-align: center;
    color: white; }
  .helpTile img {
    height: 150px; }

.our_products {
  padding-top: 16rem;
  background-repeat: no-repeat;
  background-position: 50% -650px;
  background-size: 850px; }
  .our_products .products .product {
    text-align: center;
    margin-bottom: 2rem; }
    .our_products .products .product .product__header {
      font-family: MysteryTourSans;
      text-transform: lowercase;
      color: #fff;
      height: 184px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center; }
      .our_products .products .product .product__header img {
        height: 96px; }
      .our_products .products .product .product__header p {
        margin-bottom: 0; }
      .our_products .products .product .product__header .product__link a {
        color: #fff; }
    .our_products .products .product .product__body {
      padding: 2rem 3rem 0;
      font-size: 1.02rem;
      color: #6c757d;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      @media (min-width: 768px) {
        .our_products .products .product .product__body {
          height: 488px; } }
      .our_products .products .product .product__body .product__link {
        text-align: right;
        font-weight: 600; }
  .our_products .products .product-no_body .product__header {
    height: 292px; }
  .our_products .products .product-pcba {
    border: 1px solid #2f2575; }
    .our_products .products .product-pcba .product__header {
      background: #2f2575; }
    .our_products .products .product-pcba .btn-outline-primary {
      border-color: #2f2575;
      color: #2f2575; }
      .our_products .products .product-pcba .btn-outline-primary:hover {
        background-color: #2f2575;
        color: white; }
  .our_products .products .product-wiring {
    border: 1px solid #0fafab; }
    .our_products .products .product-wiring .product__header {
      background: #0fafab; }
    .our_products .products .product-wiring .btn-outline-primary {
      border-color: #0fafab;
      color: #0fafab; }
      .our_products .products .product-wiring .btn-outline-primary:hover {
        background-color: #0fafab;
        color: white; }
  .our_products .products .product-pcb {
    border: 1px solid #00957a; }
    .our_products .products .product-pcb .product__header {
      background: #00957a; }
    .our_products .products .product-pcb .btn-outline-primary {
      border-color: #00957a;
      color: #00957a; }
      .our_products .products .product-pcb .btn-outline-primary:hover {
        background-color: #00957a;
        color: white; }
  .our_products .products .product-enclosure {
    border: 1px solid #0077be; }
    .our_products .products .product-enclosure .product__header {
      background: #0077be; }
    .our_products .products .product-enclosure .btn-outline-primary {
      border-color: #0077be;
      color: #0077be; }
      .our_products .products .product-enclosure .btn-outline-primary:hover {
        background-color: #0077be;
        color: white; }
  .our_products .products .product-coating {
    border: 1px solid #c7215d; }
    .our_products .products .product-coating .product__header {
      background: #c7215d; }
    .our_products .products .product-coating .btn-outline-primary {
      border-color: #c7215d;
      color: #c7215d; }
      .our_products .products .product-coating .btn-outline-primary:hover {
        background-color: #c7215d;
        color: white; }
  .our_products .products .product-xray {
    border: 1px solid #c73534; }
    .our_products .products .product-xray .product__header {
      background: #c73534; }
    .our_products .products .product-xray .btn-outline-primary {
      border-color: #c73534;
      color: #c73534; }
      .our_products .products .product-xray .btn-outline-primary:hover {
        background-color: #c73534;
        color: white; }
  .our_products .products .product-assembly {
    border: 1px solid #97bf0d; }
    .our_products .products .product-assembly .product__header {
      background: #97bf0d; }
    .our_products .products .product-assembly .btn-outline-primary {
      border-color: #97bf0d;
      color: #97bf0d; }
      .our_products .products .product-assembly .btn-outline-primary:hover {
        background-color: #97bf0d;
        color: white; }
  .our_products .products .product-rework {
    border: 1px solid #f1b400; }
    .our_products .products .product-rework .product__header {
      background: #f1b400; }
    .our_products .products .product-rework .btn-outline-primary {
      border-color: #f1b400;
      color: #f1b400; }
      .our_products .products .product-rework .btn-outline-primary:hover {
        background-color: #f1b400;
        color: white; }
  .our_products .products .product-engineering {
    border: 1px solid #6e2367; }
    .our_products .products .product-engineering .product__header {
      background: #6e2367; }
    .our_products .products .product-engineering .btn-outline-primary {
      border-color: #6e2367;
      color: #6e2367; }
      .our_products .products .product-engineering .btn-outline-primary:hover {
        background-color: #6e2367;
        color: white; }

.modal-close {
  position: absolute;
  right: -30px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1; }

.our_customers {
  font-size: 1.2rem;
  font-weight: 600; }
  .our_customers .marketing_section__title::after {
    border-color: #5641f7; }
  .our_customers .our_customers__customer {
    text-align: center; }
    .our_customers .our_customers__customer img {
      width: 140px; }
    .our_customers .our_customers__customer i {
      font-size: 4.25rem;
      margin: 2rem 0; }

.contact_color {
  background-color: #3d3d88; }
  .contact_color .contact_us__button {
    font-family: MysteryTourSans;
    text-transform: lowercase;
    font-size: 1.19rem; }
    .contact_color .contact_us__button span {
      font-size: 0.85rem; }
    .contact_color .contact_us__button img {
      width: 145px;
      margin-bottom: 2rem; }

.contact_text {
  text-align: center; }

.contact_map {
  align-items: center; }

.contact_background {
  background-image: url(/images/blanc.png); }

.contact_faq {
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  padding-top: 20px; }

.contact_us {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 850px; }
  @media (min-width: 992px) {
    .contact_us {
      padding: 10rem 0; } }
  @media (min-width: 1200px) {
    .contact_us {
      padding: 14rem 0; } }
  .contact_us section {
    background: #2f2575;
    color: #fff; }
    .contact_us section .marketing_section__title::after {
      border-color: #5641f7; }
    .contact_us section .contact_us__button {
      font-family: MysteryTourSans;
      text-transform: lowercase;
      font-size: 1.19rem; }
      .contact_us section .contact_us__button img {
        width: 145px;
        margin-bottom: 2rem; }

.evenement section {
  max-height: 350px;
  background-color: #e9ecef; }
  .evenement section img {
    max-height: 250px; }

.news_and_tweets .marketing_section__title {
  width: initial;
  font-size: 1.19rem;
  letter-spacing: 0.2125rem; }

@media (min-width: 768px) {
  .news_and_tweets .news_and_tweets__tweets {
    border-left: 2px solid #e9ecef; } }

.news_and_tweets .news_and_tweets__tweets td {
  border: none; }

.news_and_tweets .news_and_tweets__tweets i {
  color: #5641f7; }

.news_and_tweets .news_and_tweets__social {
  margin-top: 4rem;
  text-align: center; }
  .news_and_tweets .news_and_tweets__social img {
    width: 55px; }

.newsletter {
  background: url(/images/marketing/factory/cover.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding-top: 8rem; }
  .newsletter .newsletter_form {
    border: 1px solid #ec7b51;
    background-color: #2f2575;
    margin-bottom: 8rem; }
    .newsletter .newsletter_form .form-control {
      padding-left: 1rem;
      border-radius: 0;
      border: none;
      background: none;
      height: 3rem;
      font-size: 1.19rem; }
      .newsletter .newsletter_form .form-control::placeholder {
        color: #fff; }
    .newsletter .newsletter_form .newsletter_form__first_name {
      border-right: 1px solid #ec7b51;
      width: 50%;
      float: left; }
    .newsletter .newsletter_form .newsletter_form__last_name {
      width: 50%; }
    .newsletter .newsletter_form .newsletter_form__email {
      border-top: 1px solid #ec7b51;
      border-bottom: 1px solid #ec7b51; }
    .newsletter .newsletter_form .newsletter_form__footer {
      display: flex; }
      .newsletter .newsletter_form .newsletter_form__footer input {
        margin: 1rem; }
      .newsletter .newsletter_form .newsletter_form__footer .newsletter_form__agreement {
        padding: 0.4rem;
        line-height: 1.02rem; }
    .newsletter .newsletter_form button.btn.btn-secondary {
      border-radius: 0 !important; }

#footer {
  font-size: 1rem;
  background: #e9ecef;
  position: relative; }
  @media (min-width: 992px) {
    #footer.footer-dash {
      margin-left: 200px; }
      #footer.footer-dash .footer_legals {
        padding-left: 200px; } }
  #footer .footer__top {
    position: absolute;
    bottom: 100%;
    width: 100%;
    height: 50px; }
    #footer .footer__top path {
      fill: #e9ecef; }
  #footer .go_to_top {
    background-color: #fff;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    box-shadow: 0 0 12px #0008;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%); }
    #footer .go_to_top circle {
      fill: #2f2575; }
  #footer img.logo {
    height: 90px;
    margin: 1rem 3rem 3rem; }
  #footer h4 {
    font-family: MysteryTourSans;
    text-transform: lowercase;
    font-size: 0.85rem;
    letter-spacing: 0.2rem; }

.footer_legals {
  bottom: 0;
  right: 0;
  left: 0;
  min-height: 44px;
  background: #171546;
  color: #fff;
  text-align: center;
  padding: 0.5rem 0; }
  @media (min-width: 992px) {
    .footer_legals {
      position: fixed; } }
  .footer_legals li.language_dropdown {
    list-style: none; }
    .footer_legals li.language_dropdown > a {
      font-size: 14px;
      line-height: 21px; }
    .footer_legals li.language_dropdown .language_dropdown.dropdown-togglenav-item {
      border: #fff 1px solid;
      border-radius: 0.1rem;
      padding: 0.2rem 0.4rem;
      color: #fff; }

.page {
  padding-top: 6rem;
  padding-bottom: 6rem; }

.address-item .address-item-footer > * {
  margin: 0 0.25rem; }

.marketing_factory_panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  padding: 50vh 0;
  background: #2f2575;
  width: 325px;
  right: 0;
  top: 0;
  z-index: 1;
  color: #fff;
  font-size: 1rem;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  overflow: hidden;
  top: -50%;
  bottom: -50%; }

.marketing_factory_panel__image {
  margin: 2rem 0; }

.marketing_factory_panel__user_infos {
  padding: 0.5rem 0;
  background: #171546;
  font-weight: 600; }
  .marketing_factory_panel__user_infos .marketing_factory_panel__menu_button {
    padding: 0.4rem 2.5rem 0.4rem 0; }

.marketing_factory_panel__user_lastName {
  text-transform: uppercase; }

.marketing_factory_panel__menu_button {
  padding: 1rem 2.5rem;
  text-align: right; }
  .marketing_factory_panel__menu_button span {
    vertical-align: text-top; }
  .marketing_factory_panel__menu_button img {
    max-height: 2.6rem;
    max-width: 2.6rem;
    margin-left: 1.5rem; }
  .marketing_factory_panel__menu_button i {
    margin-left: 0.2rem;
    color: #5641f7; }
  .marketing_factory_panel__menu_button .badge {
    vertical-align: middle;
    padding: 6px 0 0;
    color: #2f2575;
    border-radius: 100%;
    min-width: 20px;
    min-height: 20px;
    margin-left: 1.5rem; }
  .marketing_factory_panel__menu_button .badge + img {
    margin-left: 1rem; }

.marketing_factory_panel__menu_button-projects {
  background: #ec7b51; }
  .marketing_factory_panel__menu_button-projects .badge {
    background: #fff; }
  .marketing_factory_panel__menu_button-projects i {
    color: #fff; }

.marketing_factory_panel__messages {
  border-top: double 3px #171546;
  border-bottom: double 3px #171546; }
  .marketing_factory_panel__messages .marketing_factory_panel__menu_button {
    padding: 0.8rem 2.5rem 0.8rem 0; }
    .marketing_factory_panel__messages .marketing_factory_panel__menu_button i {
      margin-left: 1.5rem; }
    .marketing_factory_panel__messages .marketing_factory_panel__menu_button .badge {
      margin: 0 0.5rem 0 0;
      background: #5641f7; }
    .marketing_factory_panel__messages .marketing_factory_panel__menu_button img {
      margin-right: 1rem; }

.marketing_factory_panel__button {
  text-align: center; }
  .marketing_factory_panel__button a {
    font-family: MysteryTourSans;
    text-transform: lowercase;
    letter-spacing: 0.2rem;
    font-size: 0.7rem;
    color: #ec7b51;
    border: 1px solid #5641f7;
    padding: 1rem 1.7rem 0.8rem;
    border-radius: 25px; }

.marketing_factory_panel__footer {
  height: 50px;
  background: #fff;
  margin: 0 50px 0 50px; }

.xray_designator__btn {
  border-radius: 25px;
  background-color: #6e3da4;
  color: white; }
  .xray_designator__btn:hover {
    background: white;
    border-color: #2f2575; }

.input_xray input {
  color: #6e3da4;
  font-size: 15px;
  background-color: #f6f6f6; }

.source_item .source_item__img_wrapper {
  height: 60px;
  width: 60px;
  overflow: hidden; }

.bg-red {
  background-color: #f5c6cb; }

.pcba_designator__btn {
  border-radius: 25px;
  background-color: #ec7b51;
  color: white; }

.pcba_designator__btn_unSelect {
  border-radius: 25px; }

.pcba_xray_btn {
  border-radius: 25px;
  background-color: #2f2575;
  color: white;
  border-color: #2f2575; }

.matrix-modal {
  max-width: none;
  width: 99%;
  max-height: 90vh; }
  .matrix-modal .modal-body {
    overflow: auto; }
  .matrix-modal .matrix thead tr th {
    padding: 10px 0;
    text-align: center; }
  .matrix-modal .matrix tbody tr th {
    padding: 10px 5px; }
  .matrix-modal .matrix tbody tr td {
    border-color: #fff;
    padding: 10px 0;
    text-align: center; }
    .matrix-modal .matrix tbody tr td.success, .matrix-modal .matrix tbody tr td.bg-primary, .matrix-modal .matrix tbody tr .account_table td.hover-primary:hover, .account_table .matrix-modal .matrix tbody tr td.hover-primary:hover {
      cursor: pointer; }
    .matrix-modal .matrix tbody tr td div {
      width: 28px; }
  .matrix-modal .matrix td.selectable:hover {
    background: #eee;
    cursor: pointer; }
  .matrix-modal .matrix .hover {
    background: #f4f4f4; }

.gap-0 {
  gap: 0rem !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.mt-6 {
  margin-top: 4rem !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.product_page .page_cover .btn {
  color: #ec7b51;
  border-color: #fff;
  background: rgba(23, 21, 70, 0.6); }
  .product_page .page_cover .btn span::after {
    color: #fff; }

.product_page_cover_gallery {
  padding: 1rem;
  height: 215px;
  background: #fffc;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #2f2575;
  letter-spacing: 0.4rem; }
  @media (min-width: 992px) {
    .product_page_cover_gallery {
      padding: 3rem 1rem 0;
      height: 300px; } }
  @media (min-width: 1200px) {
    .product_page_cover_gallery {
      padding: 3rem 1rem 0;
      height: 430px; } }
  .product_page_cover_gallery div {
    width: 50%;
    text-align: center;
    height: 65%; }
    @media (min-width: 992px) {
      .product_page_cover_gallery div {
        height: 55%; } }
    @media (min-width: 1200px) {
      .product_page_cover_gallery div {
        height: 50%; } }
    .product_page_cover_gallery div img {
      max-width: 100%;
      max-height: 100%; }
  .product_page_cover_gallery h4 {
    text-align: center; }
    @media (max-width: 767.98px) {
      .product_page_cover_gallery h4 {
        font-size: 1rem; } }

.product_page_cover_intro {
  padding-top: 1rem; }
  .product_page_cover_intro h1 {
    font-size: 1.3rem; }
  @media (min-width: 768px) {
    .product_page_cover_intro {
      padding-top: 3rem; }
      .product_page_cover_intro h1 {
        font-size: 1.7rem; } }
  @media (min-width: 992px) {
    .product_page_cover_intro {
      padding-top: 5rem; }
      .product_page_cover_intro h1 {
        font-size: 2.125rem; } }
  .product_page_cover_intro p {
    font-size: 1.2rem; }

.short_process .between_steps {
  transform: rotate(90deg);
  font-size: 2.5rem; }

@media (min-width: 768px) {
  .short_process .marketing_section__title {
    margin-left: 38%;
    width: 50%;
    text-align: left; }
    .short_process .marketing_section__title::after {
      margin-left: 0; }
  .short_process .between_steps {
    transform: none;
    font-size: 3.5rem; }
    .short_process .between_steps:nth-child(4) {
      width: 100%;
      transform: rotate(135deg); }
  .short_process .card {
    width: 300px; } }

@media (min-width: 992px) {
  .short_process .card {
    width: 350px; } }

@media (min-width: 1200px) {
  .short_process .between_steps:nth-child(4) {
    width: auto;
    transform: none; } }

.short_process .big_badge {
  font-size: 0.8rem;
  width: 2rem;
  height: 2rem;
  padding: 0.4rem; }

.short_process .card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0; }
  .short_process .card .short_process__icon {
    width: 35%;
    margin-right: 1rem; }
    @media (min-width: 576px) {
      .short_process .card .short_process__icon {
        width: 25%; } }
    @media (min-width: 768px) {
      .short_process .card .short_process__icon {
        width: 45%;
        height: 115px;
        margin: 1rem 0; } }
    @media (min-width: 1200px) {
      .short_process .card .short_process__icon {
        width: 100%;
        padding: 0 1rem;
        height: 130px; } }
  .short_process .card img {
    width: auto;
    max-height: 100%; }
  .short_process .card .short_process__card_info {
    text-align: left;
    padding-top: 1rem;
    display: flex;
    align-items: center; }
    @media (min-width: 768px) {
      .short_process .card .short_process__card_info {
        height: 45px; } }
    @media (min-width: 1200px) {
      .short_process .card .short_process__card_info {
        margin-top: 1rem;
        border-top: 1px solid #fff;
        height: 81px; } }
    .short_process .card .short_process__card_info p {
      margin-bottom: 0;
      font-size: 1rem;
      line-height: 1.2;
      min-height: 58px; }

.short_process .short_process_title {
  margin-top: 1rem; }

.short_process .short_process__card_title {
  height: 77px;
  font-size: 1.1rem;
  text-align: left;
  color: #ec7b51;
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .short_process .short_process__card_title {
      height: inherit; } }

.product_process {
  background: #ec7b51;
  color: #fff;
  font-size: 1.1rem; }
  @media (min-width: 1200px) {
    .product_process .marketing_section__title {
      font-size: 35px; } }
  .product_process .marketing_section__title::after {
    border-color: #2f2575; }
  .product_process .product_process_steps > * {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem 0;
    border-bottom: 1px solid #fff4; }
    .product_process .product_process_steps > *:last-child {
      border-bottom: none; }
    .product_process .product_process_steps > * img {
      max-width: 65px; }
    @media (min-width: 768px) {
      .product_process .product_process_steps > * {
        padding: 0 2rem 0 0;
        width: 50%;
        border-bottom: none; }
        .product_process .product_process_steps > * img {
          max-width: 85px; }
        .product_process .product_process_steps > * .product_process_step__badge {
          position: absolute;
          transform: translate(-50%, -5px);
          margin: 0;
          left: 50%;
          background: #2f2575;
          width: 3rem;
          height: 3rem;
          line-height: 3rem;
          font-size: 1.2rem;
          padding-top: 3px; }
        .product_process .product_process_steps > * .product_process__text {
          font-size: 1.1rem; }
        .product_process .product_process_steps > * .product_process__step_title {
          font-size: 1.5rem; }
        .product_process .product_process_steps > *:nth-child(odd) {
          margin-left: calc(50% - 3px);
          flex-direction: row;
          border-left: 3px solid #2f2575;
          padding: 0 0 0 2rem; }
        .product_process .product_process_steps > *:nth-child(even) {
          border-right: 3px solid #2f2575;
          text-align: right; }
          .product_process .product_process_steps > *:nth-child(even) .product_process__step_title {
            justify-content: flex-end; }
        .product_process .product_process_steps > *:last-child {
          border: none; } }
    @media (min-width: 1200px) {
      .product_process .product_process_steps > * {
        padding: 0 3rem 0 0; }
        .product_process .product_process_steps > * img {
          max-width: 130px; }
        .product_process .product_process_steps > *:nth-child(odd) {
          padding: 0 0 0 3rem; } }

.product_process_step__badge {
  width: 1.6rem;
  height: 1.6rem;
  line-height: 0rem;
  font-family: MysteryTourSans;
  text-transform: lowercase;
  font-size: 0.7rem;
  text-align: center;
  border-radius: 100%;
  padding-top: 1rem;
  color: #fff;
  margin-right: 0.5rem; }

.product_process__text {
  width: 70%;
  font-size: 1rem; }

.product_process__step_title {
  color: #2f2575;
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center; }

.capabilities_gallery_thumbnail {
  width: 33.33333%;
  min-height: 120px;
  float: left;
  border: 1px solid #000; }

.capabilities {
  font-size: 1rem;
  text-align: left; }
  @media (min-width: 992px) {
    .capabilities {
      font-size: 1.2rem;
      padding-top: 20rem;
      padding-bottom: 10rem;
      background-repeat: no-repeat;
      background-position: 50% -650px;
      background-size: 850px; } }
  @media (min-width: 1200px) {
    .capabilities {
      font-size: 1.2rem; } }
  .capabilities b {
    color: #ec7b51; }

.capabilities_title {
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem; }

.section_video {
  min-height: 300px;
  width: 100%;
  height: 80vh;
  text-align: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center; }

.characteristics {
  font-size: 1.3rem; }
  .characteristics .marketing_section__title::after {
    border-color: #5641f7; }
  .characteristics .characteristics_category {
    margin: 2rem 0 4rem; }
  .characteristics .characteristics_title {
    color: #ec7b51;
    font-size: 1.5rem;
    font-weight: 500;
    border-bottom: 1px solid #adb5bd;
    text-transform: uppercase; }
  .characteristics .characteristics_chars .characteristics_char {
    line-height: 1.2; }

.testimonials .marketing_section__title::after {
  border-color: #5641f7; }

.testimonials .testimonial {
  padding: 1rem 0;
  background: #2f2575;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .testimonials .testimonial img {
    height: 150px;
    border-radius: 25px;
    border: 1px solid #5641f7;
    padding: 0.5rem; }
  .testimonials .testimonial:nth-child(3n + 1) {
    background: #171546; }
    .testimonials .testimonial:nth-child(3n + 1) img {
      border-color: #ec7b51; }
  .testimonials .testimonial:nth-child(3n + 2) {
    background: #5641f7; }
    .testimonials .testimonial:nth-child(3n + 2) img {
      border-color: #2f2575; }
  @media (min-width: 992px) {
    .testimonials .testimonial {
      padding: 4rem; }
      .testimonials .testimonial .testimonial__title {
        margin: 2rem 0; } }

.testimonials .testimonial__title {
  color: #ec7b51;
  font-size: 0.9rem;
  letter-spacing: 0.2rem;
  margin: 1rem 0; }

.testimonials .testimonial__text {
  color: #fff;
  margin: 1rem;
  font-size: 1rem;
  text-align: justify;
  line-height: 1.2;
  max-width: 360px; }

.case_stories h5 {
  letter-spacing: 0.2rem; }

.case_stories p {
  font-size: 1.2rem; }

.carousel-control-prev,
.carousel-control-next {
  opacity: 1; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 100px;
  height: 100px;
  fill: #2f2575;
  background: none; }

.product_selector {
  position: fixed;
  width: 200px;
  bottom: 0;
  top: 0;
  left: 0;
  margin: 132px 0 0;
  background: #fff;
  box-shadow: 0 0 10px #0004;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2; }
  .product_selector a {
    height: 80px;
    max-height: calc((100vh - 107px) / 10);
    background: #fff; }
    .product_selector a .product_selector__product_icon {
      float: left;
      width: 67px;
      padding: 0px 15px;
      height: 100%; }
      .product_selector a .product_selector__product_icon svg {
        border-bottom: 1px solid #e9ecef;
        padding: 18px 0;
        width: 100%;
        height: 100%;
        fill: #2f2575; }
    .product_selector a .product_selector__product_name {
      font-family: MysteryTourSans;
      text-transform: lowercase;
      float: left;
      font-size: 0.6rem;
      padding: 0 0.5rem 0 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      text-align: left; }
    .product_selector a.active {
      background: #2f2575;
      color: #ec7b51;
      box-shadow: 0 0 8px #000;
      z-index: 1; }
      .product_selector a.active .product_selector__product_icon svg {
        border: none;
        fill: #ec7b51; }
    .product_selector a:first-of-type {
      border-top-right-radius: 5px; }
    .product_selector a:last-of-type {
      border-bottom-right-radius: 5px; }
    .product_selector a:hover {
      color: #ec7b51; }
      .product_selector a:hover svg {
        fill: #ec7b51; }

.product_page-pcba {
  color: #2f2575;
  padding-left: 0px; }
  @media (min-width: 992px) {
    .product_page-pcba {
      padding-left: 200px; } }
  .product_page-pcba .bg-primary, .product_page-pcba .account_table .hover-primary:hover, .account_table .product_page-pcba .hover-primary:hover {
    background-color: #2f2575 !important; }
  .product_page-pcba .text-secondary {
    color: #ec7b51 !important; }
  .product_page-pcba .bg-secondary {
    background-color: #ec7b51 !important; }
  .product_page-pcba .marketing_section:not(.news_and_tweets):not(.contact_us_section) .marketing_section__title:after {
    border-color: #ec7b51; }
  .product_page-pcba .page_cover {
    background-color: #2f2575;
    background-image: url(/images/marketing/pcba/cover.jpg); }
    .product_page-pcba .page_cover .btn {
      color: #ec7b51;
      background: rgba(23, 21, 70, 0.6); }
    .product_page-pcba .page_cover .square_benefits li {
      box-shadow: 4px 5px 0 #171546; }
    .product_page-pcba .page_cover .last_news_widget {
      background-color: #5641f7; }
  .product_page-pcba .product_page_cover_gallery {
    color: #2f2575; }
  .product_page-pcba .short_process .short_process__card_title {
    color: #ec7b51; }
  .product_page-pcba .big_badge {
    background-color: #ec7b51; }
  .product_page-pcba .keyBenefits .keyBenefit .keyBenefit__title {
    color: #ec7b51; }
  .product_page-pcba .online_setup {
    background: #ec7b51; }
    .product_page-pcba .online_setup .marketing_section__title::after {
      border-color: #2f2575; }
    .product_page-pcba .online_setup .product_process_steps > *:nth-child(odd) {
      border-left-color: #2f2575; }
    .product_page-pcba .online_setup .product_process_steps > *:nth-child(even) {
      border-right-color: #2f2575; }
  .product_page-pcba .product_process_step__badge {
    background: #2f2575 !important; }
  .product_page-pcba .product_process__step_title {
    color: #2f2575; }
  .product_page-pcba .manufacturing_process {
    background: #171546; }
    .product_page-pcba .manufacturing_process .marketing_section__title::after {
      border-color: #5641f7; }
    .product_page-pcba .manufacturing_process .product_process__step_title {
      color: #ec7b51; }
    .product_page-pcba .manufacturing_process .product_process_step__badge {
      background: #ec7b51 !important; }
    .product_page-pcba .manufacturing_process path {
      fill: #171546; }
    .product_page-pcba .manufacturing_process .product_process_steps > *:nth-child(odd) {
      border-left-color: #ec7b51; }
    .product_page-pcba .manufacturing_process .product_process_steps > *:nth-child(even) {
      border-right-color: #ec7b51; }
  .product_page-pcba .product_process_step__badge {
    background: #2f2575 !important; }
  .product_page-pcba .product_process__step_title {
    color: #2f2575; }
  .product_page-pcba .capabilities b {
    color: #ec7b51; }
  .product_page-pcba .characteristics .characteristics_title {
    color: #ec7b51; }
  .product_page-pcba .testimonials .marketing_section__title::after {
    border-color: #5641f7; }
  .product_page-pcba .testimonials .testimonial {
    background: #2f2575; }
    .product_page-pcba .testimonials .testimonial img {
      border-color: #5641f7; }
    .product_page-pcba .testimonials .testimonial:nth-child(3n + 1) {
      background: #171546; }
      .product_page-pcba .testimonials .testimonial:nth-child(3n + 1) img {
        border-color: #ec7b51; }
    .product_page-pcba .testimonials .testimonial:nth-child(3n + 2) {
      background: #5641f7; }
      .product_page-pcba .testimonials .testimonial:nth-child(3n + 2) img {
        border-color: #2f2575; }
  .product_page-pcba .testimonials .testimonial__title {
    color: #ec7b51; }
  .product_page-pcba .case_stories .marketing_section__title::after {
    border-color: #5641f7; }
  .product_page-pcba .our_customers {
    font-size: 1.3rem;
    font-weight: 800; }
    .product_page-pcba .our_customers .marketing_section__title::after {
      border-color: #5641f7; }

.product_page-wiring {
  color: #0fafab;
  padding-left: 0px; }
  @media (min-width: 992px) {
    .product_page-wiring {
      padding-left: 200px; } }
  .product_page-wiring .bg-primary, .product_page-wiring .account_table .hover-primary:hover, .account_table .product_page-wiring .hover-primary:hover {
    background-color: #0fafab !important; }
  .product_page-wiring .text-secondary {
    color: #c73534 !important; }
  .product_page-wiring .bg-secondary {
    background-color: #c73534 !important; }
  .product_page-wiring .marketing_section:not(.news_and_tweets):not(.contact_us_section) .marketing_section__title:after {
    border-color: #c73534; }
  .product_page-wiring .page_cover {
    background-color: #0fafab;
    background-image: url(/images/marketing/wiring/cover.jpg); }
    .product_page-wiring .page_cover .btn {
      color: #c73534;
      background: rgba(0, 111, 128, 0.6); }
    .product_page-wiring .page_cover .square_benefits li {
      box-shadow: 4px 5px 0 #006f80; }
    .product_page-wiring .page_cover .last_news_widget {
      background-color: #2ccfcd; }
  .product_page-wiring .product_page_cover_gallery {
    color: #0fafab; }
  .product_page-wiring .short_process .short_process__card_title {
    color: #c73534; }
  .product_page-wiring .big_badge {
    background-color: #c73534; }
  .product_page-wiring .keyBenefits .keyBenefit .keyBenefit__title {
    color: #c73534; }
  .product_page-wiring .online_setup {
    background: #c73534; }
    .product_page-wiring .online_setup .marketing_section__title::after {
      border-color: #0fafab; }
    .product_page-wiring .online_setup .product_process_steps > *:nth-child(odd) {
      border-left-color: #0fafab; }
    .product_page-wiring .online_setup .product_process_steps > *:nth-child(even) {
      border-right-color: #0fafab; }
  .product_page-wiring .product_process_step__badge {
    background: #0fafab !important; }
  .product_page-wiring .product_process__step_title {
    color: #0fafab; }
  .product_page-wiring .manufacturing_process {
    background: #006f80; }
    .product_page-wiring .manufacturing_process .marketing_section__title::after {
      border-color: #2ccfcd; }
    .product_page-wiring .manufacturing_process .product_process__step_title {
      color: #c73534; }
    .product_page-wiring .manufacturing_process .product_process_step__badge {
      background: #c73534 !important; }
    .product_page-wiring .manufacturing_process path {
      fill: #006f80; }
    .product_page-wiring .manufacturing_process .product_process_steps > *:nth-child(odd) {
      border-left-color: #c73534; }
    .product_page-wiring .manufacturing_process .product_process_steps > *:nth-child(even) {
      border-right-color: #c73534; }
  .product_page-wiring .product_process_step__badge {
    background: #0fafab !important; }
  .product_page-wiring .product_process__step_title {
    color: #0fafab; }
  .product_page-wiring .capabilities b {
    color: #c73534; }
  .product_page-wiring .characteristics .characteristics_title {
    color: #c73534; }
  .product_page-wiring .testimonials .marketing_section__title::after {
    border-color: #2ccfcd; }
  .product_page-wiring .testimonials .testimonial {
    background: #0fafab; }
    .product_page-wiring .testimonials .testimonial img {
      border-color: #2ccfcd; }
    .product_page-wiring .testimonials .testimonial:nth-child(3n + 1) {
      background: #006f80; }
      .product_page-wiring .testimonials .testimonial:nth-child(3n + 1) img {
        border-color: #c73534; }
    .product_page-wiring .testimonials .testimonial:nth-child(3n + 2) {
      background: #2ccfcd; }
      .product_page-wiring .testimonials .testimonial:nth-child(3n + 2) img {
        border-color: #0fafab; }
  .product_page-wiring .testimonials .testimonial__title {
    color: #c73534; }
  .product_page-wiring .case_stories .marketing_section__title::after {
    border-color: #2ccfcd; }
  .product_page-wiring .our_customers {
    font-size: 1.3rem;
    font-weight: 800; }
    .product_page-wiring .our_customers .marketing_section__title::after {
      border-color: #2ccfcd; }

.product_page-pcb {
  color: #00957a;
  padding-left: 0px; }
  @media (min-width: 992px) {
    .product_page-pcb {
      padding-left: 200px; } }
  .product_page-pcb .bg-primary, .product_page-pcb .account_table .hover-primary:hover, .account_table .product_page-pcb .hover-primary:hover {
    background-color: #00957a !important; }
  .product_page-pcb .text-secondary {
    color: #f1b400 !important; }
  .product_page-pcb .bg-secondary {
    background-color: #f1b400 !important; }
  .product_page-pcb .marketing_section:not(.news_and_tweets):not(.contact_us_section) .marketing_section__title:after {
    border-color: #f1b400; }
  .product_page-pcb .page_cover {
    background-color: #00957a;
    background-image: url(/images/marketing/pcb/cover.jpg); }
    .product_page-pcb .page_cover .btn {
      color: #f1b400;
      background: rgba(0, 75, 61, 0.6); }
    .product_page-pcb .page_cover .square_benefits li {
      box-shadow: 4px 5px 0 #004b3d; }
    .product_page-pcb .page_cover .last_news_widget {
      background-color: #00c161; }
  .product_page-pcb .product_page_cover_gallery {
    color: #00957a; }
  .product_page-pcb .short_process .short_process__card_title {
    color: #f1b400; }
  .product_page-pcb .big_badge {
    background-color: #f1b400; }
  .product_page-pcb .keyBenefits .keyBenefit .keyBenefit__title {
    color: #f1b400; }
  .product_page-pcb .online_setup {
    background: #f1b400; }
    .product_page-pcb .online_setup .marketing_section__title::after {
      border-color: #00957a; }
    .product_page-pcb .online_setup .product_process_steps > *:nth-child(odd) {
      border-left-color: #00957a; }
    .product_page-pcb .online_setup .product_process_steps > *:nth-child(even) {
      border-right-color: #00957a; }
  .product_page-pcb .product_process_step__badge {
    background: #00957a !important; }
  .product_page-pcb .product_process__step_title {
    color: #00957a; }
  .product_page-pcb .manufacturing_process {
    background: #004b3d; }
    .product_page-pcb .manufacturing_process .marketing_section__title::after {
      border-color: #00c161; }
    .product_page-pcb .manufacturing_process .product_process__step_title {
      color: #f1b400; }
    .product_page-pcb .manufacturing_process .product_process_step__badge {
      background: #f1b400 !important; }
    .product_page-pcb .manufacturing_process path {
      fill: #004b3d; }
    .product_page-pcb .manufacturing_process .product_process_steps > *:nth-child(odd) {
      border-left-color: #f1b400; }
    .product_page-pcb .manufacturing_process .product_process_steps > *:nth-child(even) {
      border-right-color: #f1b400; }
  .product_page-pcb .product_process_step__badge {
    background: #00957a !important; }
  .product_page-pcb .product_process__step_title {
    color: #00957a; }
  .product_page-pcb .capabilities b {
    color: #f1b400; }
  .product_page-pcb .characteristics .characteristics_title {
    color: #f1b400; }
  .product_page-pcb .testimonials .marketing_section__title::after {
    border-color: #00c161; }
  .product_page-pcb .testimonials .testimonial {
    background: #00957a; }
    .product_page-pcb .testimonials .testimonial img {
      border-color: #00c161; }
    .product_page-pcb .testimonials .testimonial:nth-child(3n + 1) {
      background: #004b3d; }
      .product_page-pcb .testimonials .testimonial:nth-child(3n + 1) img {
        border-color: #f1b400; }
    .product_page-pcb .testimonials .testimonial:nth-child(3n + 2) {
      background: #00c161; }
      .product_page-pcb .testimonials .testimonial:nth-child(3n + 2) img {
        border-color: #00957a; }
  .product_page-pcb .testimonials .testimonial__title {
    color: #f1b400; }
  .product_page-pcb .case_stories .marketing_section__title::after {
    border-color: #00c161; }
  .product_page-pcb .our_customers {
    font-size: 1.3rem;
    font-weight: 800; }
    .product_page-pcb .our_customers .marketing_section__title::after {
      border-color: #00c161; }

.product_page-enclosure {
  color: #0077be;
  padding-left: 0px; }
  @media (min-width: 992px) {
    .product_page-enclosure {
      padding-left: 200px; } }
  .product_page-enclosure .bg-primary, .product_page-enclosure .account_table .hover-primary:hover, .account_table .product_page-enclosure .hover-primary:hover {
    background-color: #0077be !important; }
  .product_page-enclosure .text-secondary {
    color: #00957a !important; }
  .product_page-enclosure .bg-secondary {
    background-color: #00957a !important; }
  .product_page-enclosure .marketing_section:not(.news_and_tweets):not(.contact_us_section) .marketing_section__title:after {
    border-color: #00957a; }
  .product_page-enclosure .page_cover {
    background-color: #0077be;
    background-image: url(/images/marketing/enclosure/cover.jpg); }
    .product_page-enclosure .page_cover .btn {
      color: #00957a;
      background: rgba(0, 61, 213, 0.6); }
    .product_page-enclosure .page_cover .square_benefits li {
      box-shadow: 4px 5px 0 #003dd5; }
    .product_page-enclosure .page_cover .last_news_widget {
      background-color: #00a0fc; }
  .product_page-enclosure .product_page_cover_gallery {
    color: #0077be; }
  .product_page-enclosure .short_process .short_process__card_title {
    color: #00957a; }
  .product_page-enclosure .big_badge {
    background-color: #00957a; }
  .product_page-enclosure .keyBenefits .keyBenefit .keyBenefit__title {
    color: #00957a; }
  .product_page-enclosure .online_setup {
    background: #00957a; }
    .product_page-enclosure .online_setup .marketing_section__title::after {
      border-color: #0077be; }
    .product_page-enclosure .online_setup .product_process_steps > *:nth-child(odd) {
      border-left-color: #0077be; }
    .product_page-enclosure .online_setup .product_process_steps > *:nth-child(even) {
      border-right-color: #0077be; }
  .product_page-enclosure .product_process_step__badge {
    background: #0077be !important; }
  .product_page-enclosure .product_process__step_title {
    color: #0077be; }
  .product_page-enclosure .manufacturing_process {
    background: #003dd5; }
    .product_page-enclosure .manufacturing_process .marketing_section__title::after {
      border-color: #00a0fc; }
    .product_page-enclosure .manufacturing_process .product_process__step_title {
      color: #00957a; }
    .product_page-enclosure .manufacturing_process .product_process_step__badge {
      background: #00957a !important; }
    .product_page-enclosure .manufacturing_process path {
      fill: #003dd5; }
    .product_page-enclosure .manufacturing_process .product_process_steps > *:nth-child(odd) {
      border-left-color: #00957a; }
    .product_page-enclosure .manufacturing_process .product_process_steps > *:nth-child(even) {
      border-right-color: #00957a; }
  .product_page-enclosure .product_process_step__badge {
    background: #0077be !important; }
  .product_page-enclosure .product_process__step_title {
    color: #0077be; }
  .product_page-enclosure .capabilities b {
    color: #00957a; }
  .product_page-enclosure .characteristics .characteristics_title {
    color: #00957a; }
  .product_page-enclosure .testimonials .marketing_section__title::after {
    border-color: #00a0fc; }
  .product_page-enclosure .testimonials .testimonial {
    background: #0077be; }
    .product_page-enclosure .testimonials .testimonial img {
      border-color: #00a0fc; }
    .product_page-enclosure .testimonials .testimonial:nth-child(3n + 1) {
      background: #003dd5; }
      .product_page-enclosure .testimonials .testimonial:nth-child(3n + 1) img {
        border-color: #00957a; }
    .product_page-enclosure .testimonials .testimonial:nth-child(3n + 2) {
      background: #00a0fc; }
      .product_page-enclosure .testimonials .testimonial:nth-child(3n + 2) img {
        border-color: #0077be; }
  .product_page-enclosure .testimonials .testimonial__title {
    color: #00957a; }
  .product_page-enclosure .case_stories .marketing_section__title::after {
    border-color: #00a0fc; }
  .product_page-enclosure .our_customers {
    font-size: 1.3rem;
    font-weight: 800; }
    .product_page-enclosure .our_customers .marketing_section__title::after {
      border-color: #00a0fc; }

.product_page-coating {
  color: #c7215d;
  padding-left: 0px; }
  @media (min-width: 992px) {
    .product_page-coating {
      padding-left: 200px; } }
  .product_page-coating .bg-primary, .product_page-coating .account_table .hover-primary:hover, .account_table .product_page-coating .hover-primary:hover {
    background-color: #c7215d !important; }
  .product_page-coating .text-secondary {
    color: #97bf0d !important; }
  .product_page-coating .bg-secondary {
    background-color: #97bf0d !important; }
  .product_page-coating .marketing_section:not(.news_and_tweets):not(.contact_us_section) .marketing_section__title:after {
    border-color: #97bf0d; }
  .product_page-coating .page_cover {
    background-color: #c7215d;
    background-image: url(/images/marketing/coating/cover.jpg); }
    .product_page-coating .page_cover .btn {
      color: #97bf0d;
      background: rgba(149, 25, 70, 0.6); }
    .product_page-coating .page_cover .square_benefits li {
      box-shadow: 4px 5px 0 #951946; }
    .product_page-coating .page_cover .last_news_widget {
      background-color: #e6495d; }
  .product_page-coating .product_page_cover_gallery {
    color: #c7215d; }
  .product_page-coating .short_process .short_process__card_title {
    color: #97bf0d; }
  .product_page-coating .big_badge {
    background-color: #97bf0d; }
  .product_page-coating .keyBenefits .keyBenefit .keyBenefit__title {
    color: #97bf0d; }
  .product_page-coating .online_setup {
    background: #97bf0d; }
    .product_page-coating .online_setup .marketing_section__title::after {
      border-color: #c7215d; }
    .product_page-coating .online_setup .product_process_steps > *:nth-child(odd) {
      border-left-color: #c7215d; }
    .product_page-coating .online_setup .product_process_steps > *:nth-child(even) {
      border-right-color: #c7215d; }
  .product_page-coating .product_process_step__badge {
    background: #c7215d !important; }
  .product_page-coating .product_process__step_title {
    color: #c7215d; }
  .product_page-coating .manufacturing_process {
    background: #951946; }
    .product_page-coating .manufacturing_process .marketing_section__title::after {
      border-color: #e6495d; }
    .product_page-coating .manufacturing_process .product_process__step_title {
      color: #97bf0d; }
    .product_page-coating .manufacturing_process .product_process_step__badge {
      background: #97bf0d !important; }
    .product_page-coating .manufacturing_process path {
      fill: #951946; }
    .product_page-coating .manufacturing_process .product_process_steps > *:nth-child(odd) {
      border-left-color: #97bf0d; }
    .product_page-coating .manufacturing_process .product_process_steps > *:nth-child(even) {
      border-right-color: #97bf0d; }
  .product_page-coating .product_process_step__badge {
    background: #c7215d !important; }
  .product_page-coating .product_process__step_title {
    color: #c7215d; }
  .product_page-coating .capabilities b {
    color: #97bf0d; }
  .product_page-coating .characteristics .characteristics_title {
    color: #97bf0d; }
  .product_page-coating .testimonials .marketing_section__title::after {
    border-color: #e6495d; }
  .product_page-coating .testimonials .testimonial {
    background: #c7215d; }
    .product_page-coating .testimonials .testimonial img {
      border-color: #e6495d; }
    .product_page-coating .testimonials .testimonial:nth-child(3n + 1) {
      background: #951946; }
      .product_page-coating .testimonials .testimonial:nth-child(3n + 1) img {
        border-color: #97bf0d; }
    .product_page-coating .testimonials .testimonial:nth-child(3n + 2) {
      background: #e6495d; }
      .product_page-coating .testimonials .testimonial:nth-child(3n + 2) img {
        border-color: #c7215d; }
  .product_page-coating .testimonials .testimonial__title {
    color: #97bf0d; }
  .product_page-coating .case_stories .marketing_section__title::after {
    border-color: #e6495d; }
  .product_page-coating .our_customers {
    font-size: 1.3rem;
    font-weight: 800; }
    .product_page-coating .our_customers .marketing_section__title::after {
      border-color: #e6495d; }

.product_page-xray {
  color: #c73534;
  padding-left: 0px; }
  @media (min-width: 992px) {
    .product_page-xray {
      padding-left: 200px; } }
  .product_page-xray .bg-primary, .product_page-xray .account_table .hover-primary:hover, .account_table .product_page-xray .hover-primary:hover {
    background-color: #c73534 !important; }
  .product_page-xray .text-secondary {
    color: #6e2367 !important; }
  .product_page-xray .bg-secondary {
    background-color: #6e2367 !important; }
  .product_page-xray .marketing_section:not(.news_and_tweets):not(.contact_us_section) .marketing_section__title:after {
    border-color: #6e2367; }
  .product_page-xray .page_cover {
    background-color: #c73534;
    background-image: url(/images/marketing/xray/cover.jpg); }
    .product_page-xray .page_cover .btn {
      color: #6e2367;
      background: rgba(129, 27, 39, 0.6); }
    .product_page-xray .page_cover .square_benefits li {
      box-shadow: 4px 5px 0 #811b27; }
    .product_page-xray .page_cover .last_news_widget {
      background-color: #ff4934; }
  .product_page-xray .product_page_cover_gallery {
    color: #c73534; }
  .product_page-xray .short_process .short_process__card_title {
    color: #6e2367; }
  .product_page-xray .big_badge {
    background-color: #6e2367; }
  .product_page-xray .keyBenefits .keyBenefit .keyBenefit__title {
    color: #6e2367; }
  .product_page-xray .online_setup {
    background: #6e2367; }
    .product_page-xray .online_setup .marketing_section__title::after {
      border-color: #c73534; }
    .product_page-xray .online_setup .product_process_steps > *:nth-child(odd) {
      border-left-color: #c73534; }
    .product_page-xray .online_setup .product_process_steps > *:nth-child(even) {
      border-right-color: #c73534; }
  .product_page-xray .product_process_step__badge {
    background: #c73534 !important; }
  .product_page-xray .product_process__step_title {
    color: #c73534; }
  .product_page-xray .manufacturing_process {
    background: #811b27; }
    .product_page-xray .manufacturing_process .marketing_section__title::after {
      border-color: #ff4934; }
    .product_page-xray .manufacturing_process .product_process__step_title {
      color: #6e2367; }
    .product_page-xray .manufacturing_process .product_process_step__badge {
      background: #6e2367 !important; }
    .product_page-xray .manufacturing_process path {
      fill: #811b27; }
    .product_page-xray .manufacturing_process .product_process_steps > *:nth-child(odd) {
      border-left-color: #6e2367; }
    .product_page-xray .manufacturing_process .product_process_steps > *:nth-child(even) {
      border-right-color: #6e2367; }
  .product_page-xray .product_process_step__badge {
    background: #c73534 !important; }
  .product_page-xray .product_process__step_title {
    color: #c73534; }
  .product_page-xray .capabilities b {
    color: #6e2367; }
  .product_page-xray .characteristics .characteristics_title {
    color: #6e2367; }
  .product_page-xray .testimonials .marketing_section__title::after {
    border-color: #ff4934; }
  .product_page-xray .testimonials .testimonial {
    background: #c73534; }
    .product_page-xray .testimonials .testimonial img {
      border-color: #ff4934; }
    .product_page-xray .testimonials .testimonial:nth-child(3n + 1) {
      background: #811b27; }
      .product_page-xray .testimonials .testimonial:nth-child(3n + 1) img {
        border-color: #6e2367; }
    .product_page-xray .testimonials .testimonial:nth-child(3n + 2) {
      background: #ff4934; }
      .product_page-xray .testimonials .testimonial:nth-child(3n + 2) img {
        border-color: #c73534; }
  .product_page-xray .testimonials .testimonial__title {
    color: #6e2367; }
  .product_page-xray .case_stories .marketing_section__title::after {
    border-color: #ff4934; }
  .product_page-xray .our_customers {
    font-size: 1.3rem;
    font-weight: 800; }
    .product_page-xray .our_customers .marketing_section__title::after {
      border-color: #ff4934; }

.product_page-assembly {
  color: #97bf0d;
  padding-left: 0px; }
  @media (min-width: 992px) {
    .product_page-assembly {
      padding-left: 200px; } }
  .product_page-assembly .bg-primary, .product_page-assembly .account_table .hover-primary:hover, .account_table .product_page-assembly .hover-primary:hover {
    background-color: #97bf0d !important; }
  .product_page-assembly .text-secondary {
    color: #2f2575 !important; }
  .product_page-assembly .bg-secondary {
    background-color: #2f2575 !important; }
  .product_page-assembly .marketing_section:not(.news_and_tweets):not(.contact_us_section) .marketing_section__title:after {
    border-color: #2f2575; }
  .product_page-assembly .page_cover {
    background-color: #97bf0d;
    background-image: url(/images/marketing/assembly/cover.jpg); }
    .product_page-assembly .page_cover .btn {
      color: #2f2575;
      background: rgba(99, 181, 13, 0.6); }
    .product_page-assembly .page_cover .square_benefits li {
      box-shadow: 4px 5px 0 #63b50d; }
    .product_page-assembly .page_cover .last_news_widget {
      background-color: #aedd00; }
  .product_page-assembly .product_page_cover_gallery {
    color: #97bf0d; }
  .product_page-assembly .short_process .short_process__card_title {
    color: #2f2575; }
  .product_page-assembly .big_badge {
    background-color: #2f2575; }
  .product_page-assembly .keyBenefits .keyBenefit .keyBenefit__title {
    color: #2f2575; }
  .product_page-assembly .online_setup {
    background: #2f2575; }
    .product_page-assembly .online_setup .marketing_section__title::after {
      border-color: #97bf0d; }
    .product_page-assembly .online_setup .product_process_steps > *:nth-child(odd) {
      border-left-color: #97bf0d; }
    .product_page-assembly .online_setup .product_process_steps > *:nth-child(even) {
      border-right-color: #97bf0d; }
  .product_page-assembly .product_process_step__badge {
    background: #97bf0d !important; }
  .product_page-assembly .product_process__step_title {
    color: #97bf0d; }
  .product_page-assembly .manufacturing_process {
    background: #63b50d; }
    .product_page-assembly .manufacturing_process .marketing_section__title::after {
      border-color: #aedd00; }
    .product_page-assembly .manufacturing_process .product_process__step_title {
      color: #2f2575; }
    .product_page-assembly .manufacturing_process .product_process_step__badge {
      background: #2f2575 !important; }
    .product_page-assembly .manufacturing_process path {
      fill: #63b50d; }
    .product_page-assembly .manufacturing_process .product_process_steps > *:nth-child(odd) {
      border-left-color: #2f2575; }
    .product_page-assembly .manufacturing_process .product_process_steps > *:nth-child(even) {
      border-right-color: #2f2575; }
  .product_page-assembly .product_process_step__badge {
    background: #97bf0d !important; }
  .product_page-assembly .product_process__step_title {
    color: #97bf0d; }
  .product_page-assembly .capabilities b {
    color: #2f2575; }
  .product_page-assembly .characteristics .characteristics_title {
    color: #2f2575; }
  .product_page-assembly .testimonials .marketing_section__title::after {
    border-color: #aedd00; }
  .product_page-assembly .testimonials .testimonial {
    background: #97bf0d; }
    .product_page-assembly .testimonials .testimonial img {
      border-color: #aedd00; }
    .product_page-assembly .testimonials .testimonial:nth-child(3n + 1) {
      background: #63b50d; }
      .product_page-assembly .testimonials .testimonial:nth-child(3n + 1) img {
        border-color: #2f2575; }
    .product_page-assembly .testimonials .testimonial:nth-child(3n + 2) {
      background: #aedd00; }
      .product_page-assembly .testimonials .testimonial:nth-child(3n + 2) img {
        border-color: #97bf0d; }
  .product_page-assembly .testimonials .testimonial__title {
    color: #2f2575; }
  .product_page-assembly .case_stories .marketing_section__title::after {
    border-color: #aedd00; }
  .product_page-assembly .our_customers {
    font-size: 1.3rem;
    font-weight: 800; }
    .product_page-assembly .our_customers .marketing_section__title::after {
      border-color: #aedd00; }

.product_page-rework {
  color: #f1b400;
  padding-left: 0px; }
  @media (min-width: 992px) {
    .product_page-rework {
      padding-left: 200px; } }
  .product_page-rework .bg-primary, .product_page-rework .account_table .hover-primary:hover, .account_table .product_page-rework .hover-primary:hover {
    background-color: #f1b400 !important; }
  .product_page-rework .text-secondary {
    color: #0077be !important; }
  .product_page-rework .bg-secondary {
    background-color: #0077be !important; }
  .product_page-rework .marketing_section:not(.news_and_tweets):not(.contact_us_section) .marketing_section__title:after {
    border-color: #0077be; }
  .product_page-rework .page_cover {
    background-color: #f1b400;
    background-image: url(/images/marketing/rework/cover.jpg); }
    .product_page-rework .page_cover .btn {
      color: #0077be;
      background: rgba(241, 143, 0, 0.6); }
    .product_page-rework .page_cover .square_benefits li {
      box-shadow: 4px 5px 0 #f18f00; }
    .product_page-rework .page_cover .last_news_widget {
      background-color: #ffdb00; }
  .product_page-rework .product_page_cover_gallery {
    color: #f1b400; }
  .product_page-rework .short_process .short_process__card_title {
    color: #0077be; }
  .product_page-rework .big_badge {
    background-color: #0077be; }
  .product_page-rework .keyBenefits .keyBenefit .keyBenefit__title {
    color: #0077be; }
  .product_page-rework .online_setup {
    background: #0077be; }
    .product_page-rework .online_setup .marketing_section__title::after {
      border-color: #f1b400; }
    .product_page-rework .online_setup .product_process_steps > *:nth-child(odd) {
      border-left-color: #f1b400; }
    .product_page-rework .online_setup .product_process_steps > *:nth-child(even) {
      border-right-color: #f1b400; }
  .product_page-rework .product_process_step__badge {
    background: #f1b400 !important; }
  .product_page-rework .product_process__step_title {
    color: #f1b400; }
  .product_page-rework .manufacturing_process {
    background: #f18f00; }
    .product_page-rework .manufacturing_process .marketing_section__title::after {
      border-color: #ffdb00; }
    .product_page-rework .manufacturing_process .product_process__step_title {
      color: #0077be; }
    .product_page-rework .manufacturing_process .product_process_step__badge {
      background: #0077be !important; }
    .product_page-rework .manufacturing_process path {
      fill: #f18f00; }
    .product_page-rework .manufacturing_process .product_process_steps > *:nth-child(odd) {
      border-left-color: #0077be; }
    .product_page-rework .manufacturing_process .product_process_steps > *:nth-child(even) {
      border-right-color: #0077be; }
  .product_page-rework .product_process_step__badge {
    background: #f1b400 !important; }
  .product_page-rework .product_process__step_title {
    color: #f1b400; }
  .product_page-rework .capabilities b {
    color: #0077be; }
  .product_page-rework .characteristics .characteristics_title {
    color: #0077be; }
  .product_page-rework .testimonials .marketing_section__title::after {
    border-color: #ffdb00; }
  .product_page-rework .testimonials .testimonial {
    background: #f1b400; }
    .product_page-rework .testimonials .testimonial img {
      border-color: #ffdb00; }
    .product_page-rework .testimonials .testimonial:nth-child(3n + 1) {
      background: #f18f00; }
      .product_page-rework .testimonials .testimonial:nth-child(3n + 1) img {
        border-color: #0077be; }
    .product_page-rework .testimonials .testimonial:nth-child(3n + 2) {
      background: #ffdb00; }
      .product_page-rework .testimonials .testimonial:nth-child(3n + 2) img {
        border-color: #f1b400; }
  .product_page-rework .testimonials .testimonial__title {
    color: #0077be; }
  .product_page-rework .case_stories .marketing_section__title::after {
    border-color: #ffdb00; }
  .product_page-rework .our_customers {
    font-size: 1.3rem;
    font-weight: 800; }
    .product_page-rework .our_customers .marketing_section__title::after {
      border-color: #ffdb00; }

.product_page-engineering {
  color: #6e2367;
  padding-left: 0px; }
  @media (min-width: 992px) {
    .product_page-engineering {
      padding-left: 200px; } }
  .product_page-engineering .bg-primary, .product_page-engineering .account_table .hover-primary:hover, .account_table .product_page-engineering .hover-primary:hover {
    background-color: #6e2367 !important; }
  .product_page-engineering .text-secondary {
    color: #00aeab !important; }
  .product_page-engineering .bg-secondary {
    background-color: #00aeab !important; }
  .product_page-engineering .marketing_section:not(.news_and_tweets):not(.contact_us_section) .marketing_section__title:after {
    border-color: #00aeab; }
  .product_page-engineering .page_cover {
    background-color: #6e2367;
    background-image: url(/images/marketing/engineering/cover.jpg); }
    .product_page-engineering .page_cover .btn {
      color: #00aeab;
      background: rgba(83, 30, 103, 0.6); }
    .product_page-engineering .page_cover .square_benefits li {
      box-shadow: 4px 5px 0 #531e67; }
    .product_page-engineering .page_cover .last_news_widget {
      background-color: #6e3da4; }
  .product_page-engineering .product_page_cover_gallery {
    color: #6e2367; }
  .product_page-engineering .short_process .short_process__card_title {
    color: #00aeab; }
  .product_page-engineering .big_badge {
    background-color: #00aeab; }
  .product_page-engineering .keyBenefits .keyBenefit .keyBenefit__title {
    color: #00aeab; }
  .product_page-engineering .online_setup {
    background: #00aeab; }
    .product_page-engineering .online_setup .marketing_section__title::after {
      border-color: #6e2367; }
    .product_page-engineering .online_setup .product_process_steps > *:nth-child(odd) {
      border-left-color: #6e2367; }
    .product_page-engineering .online_setup .product_process_steps > *:nth-child(even) {
      border-right-color: #6e2367; }
  .product_page-engineering .product_process_step__badge {
    background: #6e2367 !important; }
  .product_page-engineering .product_process__step_title {
    color: #6e2367; }
  .product_page-engineering .manufacturing_process {
    background: #531e67; }
    .product_page-engineering .manufacturing_process .marketing_section__title::after {
      border-color: #6e3da4; }
    .product_page-engineering .manufacturing_process .product_process__step_title {
      color: #00aeab; }
    .product_page-engineering .manufacturing_process .product_process_step__badge {
      background: #00aeab !important; }
    .product_page-engineering .manufacturing_process path {
      fill: #531e67; }
    .product_page-engineering .manufacturing_process .product_process_steps > *:nth-child(odd) {
      border-left-color: #00aeab; }
    .product_page-engineering .manufacturing_process .product_process_steps > *:nth-child(even) {
      border-right-color: #00aeab; }
  .product_page-engineering .product_process_step__badge {
    background: #6e2367 !important; }
  .product_page-engineering .product_process__step_title {
    color: #6e2367; }
  .product_page-engineering .capabilities b {
    color: #00aeab; }
  .product_page-engineering .characteristics .characteristics_title {
    color: #00aeab; }
  .product_page-engineering .testimonials .marketing_section__title::after {
    border-color: #6e3da4; }
  .product_page-engineering .testimonials .testimonial {
    background: #6e2367; }
    .product_page-engineering .testimonials .testimonial img {
      border-color: #6e3da4; }
    .product_page-engineering .testimonials .testimonial:nth-child(3n + 1) {
      background: #531e67; }
      .product_page-engineering .testimonials .testimonial:nth-child(3n + 1) img {
        border-color: #00aeab; }
    .product_page-engineering .testimonials .testimonial:nth-child(3n + 2) {
      background: #6e3da4; }
      .product_page-engineering .testimonials .testimonial:nth-child(3n + 2) img {
        border-color: #6e2367; }
  .product_page-engineering .testimonials .testimonial__title {
    color: #00aeab; }
  .product_page-engineering .case_stories .marketing_section__title::after {
    border-color: #6e3da4; }
  .product_page-engineering .our_customers {
    font-size: 1.3rem;
    font-weight: 800; }
    .product_page-engineering .our_customers .marketing_section__title::after {
      border-color: #6e3da4; }

.product_page-wiring .page_cover .btn {
  color: white; }

.product_page-wiring .short_process .short_process__card_title {
  color: white; }

.product_page-wiring .manufacturing_process .process__step_title {
  color: white; }

.product_page-enclosure .page_cover .btn {
  color: white; }

.product_page-enclosure .short_process .short_process__card_title {
  color: white; }

.product_page-enclosure .online_setup .product_process__step_title {
  color: white; }

.product_page-xray .page_cover .btn {
  color: white; }

.product_page-xray .short_process .short_process__card_title {
  color: white; }

.product_page-xray .manufacturing_process .process__step_title {
  color: white; }

.product_page-xray .manufacturing_process {
  background: #c73534; }

.product_page-xray .manufacturing_process .product_process__step_title {
  color: white; }

.product_page-rework .manufacturing_process {
  background: #f1b400; }

.product_page-assembly .product_process .product_process_step__badge {
  background: #97bf0d !important; }

.tunnel_header {
  height: 100px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #2f2575;
  padding: 0; }

.tunnel_header_logo {
  background: #fff;
  height: 100%;
  width: 250px;
  padding: 1rem;
  text-align: center;
  float: left; }
  .tunnel_header_logo img {
    height: 100%; }

html:lang(it) .square_product_link,
html:lang(es) .square_product_link {
  font-size: 0.9rem; }
  @media (min-width: 768px) {
    html:lang(it) .square_product_link,
    html:lang(es) .square_product_link {
      font-size: 0.6rem; } }
  @media (min-width: 992px) {
    html:lang(it) .square_product_link,
    html:lang(es) .square_product_link {
      font-size: 0.75rem; } }
  @media (min-width: 1200px) {
    html:lang(it) .square_product_link,
    html:lang(es) .square_product_link {
      font-size: 0.9rem; } }

html:lang(de) .square_product_link {
  font-size: 0.9rem; }
  @media (min-width: 768px) {
    html:lang(de) .square_product_link {
      font-size: 0.51rem; }
      html:lang(de) .square_product_link a {
        padding: 4px; } }
  @media (min-width: 992px) {
    html:lang(de) .square_product_link {
      font-size: 0.653rem; } }
  @media (min-width: 1200px) {
    html:lang(de) .square_product_link {
      font-size: 0.79rem; } }

@media (min-width: 768px) {
  html:lang(nl) .square_product_link {
    font-size: 0.61rem; }
    html:lang(nl) .square_product_link .badge {
      padding-top: 0.3rem !important; }
      html:lang(nl) .square_product_link .badge span {
        font-size: 10px; } }

@media (min-width: 992px) {
  html:lang(nl) .square_product_link {
    font-size: 0.75rem; }
    html:lang(nl) .square_product_link .badge {
      padding-top: 0.5rem !important;
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important; }
      html:lang(nl) .square_product_link .badge span {
        font-size: 12px; } }

@media (min-width: 1200px) {
  html:lang(nl) .square_product_link {
    font-size: 0.9rem; }
    html:lang(nl) .square_product_link .badge {
      padding-left: 1rem !important;
      padding-right: 1rem !important; }
      html:lang(nl) .square_product_link .badge span {
        font-size: 12px; } }

#account {
  background: #f8f9fa;
  padding-top: 74px;
  height: 100vh; }
  #account .th_sortable {
    cursor: pointer; }
  #account .bg-primaryLight {
    background-color: #5641f7; }
  #account .bg-primaryDark {
    background-color: #171546; }
  #account .action_border {
    border-right: 1px solid #ced4da; }
  #account .action_margin {
    margin-right: 5px;
    margin-left: 5px; }
  #account .carousel_dashboard_prev {
    background-color: #dee2e6;
    height: 35px;
    top: 130px;
    width: 8%; }
  #account .account_searchBar_btn {
    background-color: #2f2575;
    color: white;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-color: #2f2575;
    z-index: 10;
    height: 100%; }
  #account .account_searchBar_input {
    border-color: #2f2575;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px; }
  #account .account_team-table {
    background-color: white;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    border-left: 1px solid #ced4da; }
  #account .account_team-table-end {
    border-right: 1px solid #ced4da; }
  #account .team_btn {
    border-radius: 25px;
    width: 95%; }
  #account .account_sidebar {
    z-index: 2;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    bottom: 0;
    width: 250px;
    background: #171546;
    box-shadow: 0 0 20px #0002;
    color: #fff; }
    #account .account_sidebar .account_sidebar__nav_text {
      display: flex;
      align-items: center;
      text-align: left; }
    @media (max-width: 991.98px) {
      #account .account_sidebar {
        width: 60px; }
        #account .account_sidebar .account_sidebar__nav_text {
          display: none; } }
    #account .account_sidebar .account_sidebar_logo {
      background: #fff;
      text-align: center;
      padding: 1rem;
      min-height: 50px;
      max-height: 100px; }
      #account .account_sidebar .account_sidebar_logo img {
        height: 100%; }
    #account .account_sidebar .account_sidebar_factory {
      background: #2f2575;
      min-height: 100px;
      max-height: 200px;
      padding: 1rem;
      text-align: center; }
      #account .account_sidebar .account_sidebar_factory img {
        height: 100%; }
    #account .account_sidebar .account_sidebar__user a {
      color: #fff; }
    #account .account_sidebar .account_sidebar__user {
      background: #2f2575; }
      #account .account_sidebar .account_sidebar__user a {
        text-decoration: none; }
      #account .account_sidebar .account_sidebar__user .list-group-item {
        background: none;
        border: none; }
    #account .account_sidebar .account_sidebar_btn {
      background-color: #171546;
      border-bottom: 1px solid #2f2575; }
      #account .account_sidebar .account_sidebar_btn:hover {
        background-color: #2f2575; }
    #account .account_sidebar .account_sidebar__glyph {
      width: 100%; }
      #account .account_sidebar .account_sidebar__glyph i {
        font-size: 20px; }
    #account .account_sidebar .account_sidebar__notifications {
      border: 0 #2f2575 solid;
      border-width: 4px 0;
      background-color: #2f2575; }
  #account .account_factory_infos {
    background: #ec7b51;
    padding: 1rem;
    color: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    text-align: center; }
  #account #content {
    min-height: 95vh;
    bottom: 44px;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem; }
    @media (max-width: 991.98px) {
      #account #content {
        left: 60px !important; } }
    @media (max-width: 767.98px) {
      #account #content {
        bottom: 0; } }
    #account #content .content-header {
      display: flex;
      align-items: center; }
      #account #content .content-header .content-header-title {
        margin: 0;
        margin-right: 24px;
        padding: 0px 24px 5px 0; }
      #account #content .content-header .nav-link.active {
        background: #ec7b51; }

.footer_legals-account {
  position: fixed;
  z-index: -1; }
  .footer_legals-account .footer_legals__versions {
    width: 250px; }
    @media (max-width: 991.98px) {
      .footer_legals-account .footer_legals__versions {
        width: inherit; } }

#footer.dash {
  margin-left: 250px; }

.project_list__project_type_td {
  color: #fff;
  width: 45px;
  height: 45px;
  border-radius: 77%; }
  .project_list__project_type_td img {
    height: 43px;
    padding-top: 5px;
    padding-bottom: 5px; }

.espace {
  height: 10px; }

.account_table .disabled {
  opacity: 0.6; }

.account_table .account_table__head {
  display: none;
  margin-bottom: 1rem;
  margin-left: 0;
  margin-right: 0; }
  .account_table .account_table__head > * {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #e9ecef;
    font-size: 17px;
    font-weight: 600; }
  @media (min-width: 992px) {
    .account_table .account_table__head {
      display: flex; } }

.account_table .account_table__body > div {
  border: 1px solid #2f2575;
  margin: 1rem 0;
  padding: 0 1rem; }
  .account_table .account_table__body > div > .row {
    flex-direction: column;
    justify-content: stretch;
    margin-top: 0.5rem;
    margin-left: 0;
    margin-right: 0; }
    .account_table .account_table__body > div > .row,
    .account_table .account_table__body > div > .row * {
      text-decoration: none; }
    @media (min-width: 992px) {
      .account_table .account_table__body > div > .row:hover > * {
        color: #ec7b51; }
        .account_table .account_table__body > div > .row:hover > *.cell {
          background: #ec7b51;
          color: #fff; }
        .account_table .account_table__body > div > .row:hover > * .text-secondary,
        .account_table .account_table__body > div > .row:hover > * .text-muted {
          color: #fff !important; } }
    .account_table .account_table__body > div > .row > * {
      min-height: 65px;
      display: flex; }
      .account_table .account_table__body > div > .row > *:first-child {
        background: #fff2; }
      @media (min-width: 992px) {
        .account_table .account_table__body > div > .row > *:not(:first-child) {
          border-left: 1px solid #ced4da; } }
      .account_table .account_table__body > div > .row > *.cell {
        font-size: 17px;
        flex-direction: column;
        justify-content: center;
        line-height: 1.25rem; }
        @media (min-width: 992px) {
          .account_table .account_table__body > div > .row > *.cell {
            border-left: 1px solid #ced4da;
            border-top: 1px solid #ced4da;
            border-bottom: 1px solid #ced4da; } }
      .account_table .account_table__body > div > .row > *.account_table__actions {
        font-weight: 600;
        font-size: 18px;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        justify-content: space-around;
        align-items: stretch;
        cursor: pointer; }
        .account_table .account_table__body > div > .row > *.account_table__actions > * {
          flex: 1 1 auto;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center; }
          .account_table .account_table__body > div > .row > *.account_table__actions > *:not(:first-child) {
            border-left: 1px solid #ced4da; }
          .account_table .account_table__body > div > .row > *.account_table__actions > *:hover {
            background-color: #2f2575;
            color: #fff; }
    @media (min-width: 992px) {
      .account_table .account_table__body > div > .row {
        flex-direction: row; } }
  @media (min-width: 992px) {
    .account_table .account_table__body > div {
      border: none;
      padding: 0; } }

.account_carts a:hover {
  text-decoration: none; }

.pcb_block {
  background-color: #f6f6f6;
  border: 1px solid #e5e5e5;
  padding-top: 15px; }

.my_project_border {
  border-left: 1px solid #e9ecef; }

.my_project_header {
  font-size: 17px;
  font-weight: 600; }

.my_project_table {
  background-color: #f6f6f6;
  border-left: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  height: 65px;
  font-size: 18px; }

.my_project_action {
  border-left: 1px solid #ced4da;
  font-weight: 600;
  height: 65px;
  font-size: 18px; }

.my_project_row div {
  font-weight: 500;
  height: 80px; }

.my_project_endTable {
  border-right: 1px solid #ced4da; }

.my_project_hover :hover #hover {
  background-color: #ec7b51;
  color: white; }
  .my_project_hover :hover #hover i {
    color: white; }

.my_project_hover :hover #go {
  background-color: #ec7b51; }
  .my_project_hover :hover #go i {
    color: white; }

.my_project_hover :hover i {
  color: #ec7b51; }

.status {
  min-width: 20px;
  padding: 2px;
  height: 20px;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 0.25rem;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 0.85rem; }

.guided-tour {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1031;
  color: #fff;
  background: #0002; }

.guided_tour__slide .modal-content,
.guided_tour__slide .modal-footer,
.guided_tour__slide .popover {
  border-color: #ec7b51; }

.guided_tour__slide .popover {
  box-shadow: 0 0 20px #000b; }

.guided_tour__slide .bs-popover-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #ec7b51 !important; }

.guided_tour__slide .bs-popover-auto[x-placement^="bottom"] .arrow:after {
  border-bottom-color: #ec7b51 !important; }

.guided_tour__slide .bs-popover-auto[x-placement^="left"] .arrow:before {
  border-left-color: #ec7b51 !important; }

.guided_tour__slide .bs-popover-auto[x-placement^="left"] .arrow:after {
  border-left-color: #ec7b51 !important; }

.guided_tour__slide .bs-popover-auto[x-placement^="top"] .arrow:before {
  border-top-color: #ec7b51 !important; }

.guided_tour__slide .bs-popover-auto[x-placement^="top"] .arrow:after {
  border-top-color: #ec7b51 !important; }

.guided_tour__slide .bs-popover-auto[x-placement^="right"] .arrow:before {
  border-right-color: #ec7b51 !important; }

.guided_tour__slide .bs-popover-auto[x-placement^="right"] .arrow:after {
  border-right-color: #ec7b51 !important; }

.guided_tour__slide .popover-header:before {
  display: none !important; }

.guided_tour__slide .modal-header,
.guided_tour__slide .popover-header {
  background-color: #ec7b51;
  color: white; }

.guided-tour_content {
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 200px; }

.guided-tour_nav {
  position: absolute;
  bottom: 1rem;
  height: 200px;
  width: 100%; }

.guided-tour_close {
  position: absolute;
  top: 1rem;
  right: 1rem; }

.border_phone {
  border-bottom: 1px solid #ced4da;
  padding-bottom: 20px; }

.project_hover :hover {
  background-color: #ec7b51; }

.steps_wrapper {
  width: calc(100% - 205px);
  padding: 0 1rem 0 0.25rem; }
  @media (min-width: 1600px) {
    .steps_wrapper {
      width: calc(100% - 260px); } }

.projects {
  display: flex;
  justify-content: center;
  height: 100vh; }

#pricing {
  max-width: 205px; }
  #pricing .product_picto .product_picto__wrapper {
    max-height: 60px;
    max-width: 60px; }
  #pricing .product_picto h5 {
    font-size: 0.8rem; }
  @media (min-width: 1600px) {
    #pricing {
      max-width: 260px; }
      #pricing .product_picto .product_picto__wrapper {
        max-height: 60px;
        max-width: 60px; }
      #pricing .product_picto h5 {
        font-size: 1rem; } }

.quotation-quantity-and-delay-settings {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .quotation-quantity-and-delay-settings .quantity_delay_settings__number {
    font-size: 20px;
    line-height: 1; }
  .quotation-quantity-and-delay-settings .quantity_delay_settings__number_unity {
    line-height: 1; }
  .quotation-quantity-and-delay-settings > .row {
    padding: 0.25rem 0 0; }
    .quotation-quantity-and-delay-settings > .row > div:first-child {
      border-right: 1px solid #fff; }
  @media (min-width: 1600px) {
    .quotation-quantity-and-delay-settings .quantity_delay_settings__number {
      font-size: 30px;
      line-height: 1.5; }
    .quotation-quantity-and-delay-settings .quantity_delay_settings__number_unity {
      line-height: 1.2; } }

.quotation_table {
  border-collapse: separate;
  border-spacing: 10px; }

.quotation_glyph {
  font-size: 19px; }

.varnish_caracteristics {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; }
  .varnish_caracteristics > * {
    max-width: 220px; }

.matrix {
  height: 100%; }
  .matrix .matrix__menu {
    text-align: center; }
  .matrix .matrix__prices {
    height: 100%; }

.price_matrix_table {
  height: 100%; }
  .price_matrix_table .matrix_table {
    height: 100%;
    overflow: hidden; }
  .price_matrix_table .matrix_table_rowH {
    width: 125px;
    float: left;
    overflow-x: scroll;
    overflow-y: hidden;
    height: calc(100% - 40px - 26px);
    margin-top: 66px; }
    .price_matrix_table .matrix_table_rowH table tbody th {
      height: 65px;
      border-right: 0; }
  .price_matrix_table .matrix_table__colH {
    overflow-y: scroll;
    overflow-x: hidden; }
    .price_matrix_table .matrix_table__colH table {
      margin: 0; }
  .price_matrix_table .matrix_table__body {
    overflow: scroll;
    height: calc(100% - 40px - 26px); }
    .price_matrix_table .matrix_table__body tr:first-child > td {
      border-top: 0; }

.price-matrix {
  background: #fff;
  width: 100%;
  height: 100%; }
  .price-matrix .scale-hor line {
    stroke: #dee2e6;
    stroke-width: 1px;
    stroke-dasharray: 10, 5; }
  .price-matrix .price-area {
    fill: rgba(170, 170, 170, 0.8); }
  .price-matrix .scale-vert {
    cursor: pointer; }
    .price-matrix .scale-vert path {
      opacity: 0.6;
      fill: #fff; }
    .price-matrix .scale-vert:nth-child(odd) path {
      fill: #f1f1f1; }
    .price-matrix .scale-vert.scale-vert-active path {
      fill: #7265ce; }
    .price-matrix .scale-vert.scale-vert-active .label {
      fill: #2f2575; }
  .price-matrix .quantity-group .point {
    cursor: pointer;
    fill: #fff;
    stroke: #aaa;
    stroke-width: 2px; }
    .price-matrix .quantity-group .point.point-delay-warning {
      stroke: #ec7b51; }
    .price-matrix .quantity-group .point.point-delay-hover {
      stroke-width: 5px;
      fill: #aaa; }
      .price-matrix .quantity-group .point.point-delay-hover.point-delay-warning {
        fill: #ec7b51; }
    .price-matrix .quantity-group .point.point-delay-select {
      stroke-width: 5px;
      fill: #2f2575;
      stroke: #2f2575; }
      .price-matrix .quantity-group .point.point-delay-select.point-delay-warning {
        stroke: #ec7b51;
        fill: #ec7b51; }
  .price-matrix .quantity-group .quantity-group-rect {
    cursor: pointer;
    fill: #fff;
    stroke: #aaa;
    stroke-width: 2px;
    opacity: 0; }
  .price-matrix .quantity-group.quantity-group-select .quantity-group-rect, .price-matrix .quantity-group.quantity-group-hover .quantity-group-rect {
    opacity: 1; }
  .price-matrix .quantity-group.quantity-group-select .quantity-group-rect,
  .price-matrix .quantity-group.quantity-group-select .point:not(.point-delay-hover) {
    stroke: #2f2575; }
    .price-matrix .quantity-group.quantity-group-select .quantity-group-rect.point-delay-warning,
    .price-matrix .quantity-group.quantity-group-select .point:not(.point-delay-hover).point-delay-warning {
      stroke: #ec7b51; }
  .price-matrix .delayCurve {
    stroke: #aaa;
    stroke-width: 2px; }
    .price-matrix .delayCurve.delayCurve-hover {
      stroke-width: 4px; }
    .price-matrix .delayCurve.delayCurve-select {
      stroke: #2f2575;
      stroke-width: 4px; }

.chart-tooltip {
  position: absolute;
  min-height: 50px;
  min-width: 50px;
  max-width: 250px;
  background: #fff;
  border: 2px solid #2f2575;
  border-radius: 10px; }
  .chart-tooltip table {
    border-radius: 6px;
    overflow: hidden;
    margin: 0; }
  .chart-tooltip p {
    margin: 0; }
  .chart-tooltip svg {
    width: 2px;
    height: 15px;
    position: absolute; }
    .chart-tooltip svg path {
      stroke: #2f2575;
      stroke-width: 4px; }
  .chart-tooltip.top svg {
    bottom: -17px; }
  .chart-tooltip.bottom svg {
    top: -17px; }
  .chart-tooltip.left svg {
    right: 6px; }
  .chart-tooltip.right svg {
    left: 6px; }
  .chart-tooltip .pricing__next {
    cursor: pointer;
    padding: 1rem;
    text-align: center; }

.matrix_pricing__cta {
  color: #fff;
  font-weight: bold;
  background: #2f2575;
  text-align: center;
  padding: 1rem;
  font-size: 1rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  cursor: pointer; }

.matrix_table__cell {
  width: 120px;
  height: 40px; }

.manual_quotation_table .manual_quotation_table__totals {
  font-size: 1.1rem;
  text-align: right; }

a.bg-secondary i.text-exclamation {
  color: #fff !important; }

i.text-exclamation {
  color: #dc3545 !important; }
